import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Snackbar } from '@mui/material';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search'

import msgUser1 from '../../assets/images/msgUser1.png';
import chat_img from '../../assets/images/chat_img.png';
import sendAero from '../../assets/images/send_Aero.svg';
import attachFile from '../../assets/images/attach_file.svg';

import "../../App.css";
import "../subject/Subject.css";
import "../private-class/PrivateClass.css";
import "../overview/Overview.css";
import "../responsive/Responsive.css";

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useSocket from '../../custom-hooks/useSocket';
import useTranslations from '../../custom-hooks/useTranslations';
import useClasses from '../../custom-hooks/useClasses';
import { API_BASE_URL } from '../../config/apiConfig';
import { axiosPost } from '../../services/api';

const Chat = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { classId } = useParams();
    const { socket, isConnected } = useSocket(); 
    const translations = useTranslations();
    const { classes, getClass } = useClasses();
    const cls = getClass(atob(classId));
    const [value, setValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [dataType, setDataType] = useState(1);
    const [chatEvents, setChatEvents] = useState([]);
    const [participantList, setParticipantList] = useState([]);
    const [isInvalid, setIsInvalid] = useState(false);

    const translation = {
        ...translations[8]?.PrivateClassDashboard,
        ...translations[12]?.ClassDetail,
        ...translations[21]?.CommonFrontend,
        ...translations[26]?.QuickCall,
    }

    const [placeholder, setPlaceholder] = useState(translation?.WriteYourMessage);
    
    const profileInfo = JSON.parse(sessionStorage.getItem("ProfileInfo"));
    const participantLists = useSelector(({ getParticipanetsListReducer }) => getParticipanetsListReducer.participanets);
    const chatBackup = useSelector(({ getChatBackupReducer }) => getChatBackupReducer.chat);

    

    const handleOnChange = (e) => {
        setValue(e.target.value);
    };

    const onSubmit = (event) => {
        event.preventDefault();
        if (value.trim() === "") return;
        setIsLoading(true);
        setPlaceholder(translation?.Sending)
    
        const currentDate = new Date();
        const options = { 
          weekday: 'short', 
          month: 'short', 
          day: 'numeric', 
          year: 'numeric', 
          hour: '2-digit', 
          minute: 'numeric', 
          second: 'numeric', 
          timeZoneName: 'short', 
          timeZone: 'Asia/Kolkata',
          hour12: false // Set hour12 to false for 24-hour format
        };
    
        const formattedDate = currentDate.toLocaleString('en-US', options).replace(/,/g, '');
        const payload = {
          Authorization: JSON.parse(sessionStorage.getItem("loginToken"))?.idToken,
          ClassID: `PC${cls?.ID}`,
        //   DataType: dataType.toString(), // 1 is for text 2 for image 3 is for doc if video then 4
          DataType: "1",
          Message: value,
          Person: profileInfo?.UserName,
          TutorID: participantList[0]?.ID,
          UserPic: profileInfo?.ProfilePictureUrl,
          UserUID: profileInfo?.UID,
          StudentID: profileInfo?.ID,
          Time: formattedDate,
        }
    
        socket.emit('YasaChat', JSON.stringify(payload));
        setValue('');
        setIsLoading(false);
        setPlaceholder(translation?.WriteYourMessage)
    }

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      };
    
    const handleFileInput = async (e) => {
        let base64images = [];
        for (let i = 0; i < e.target.files.length; i++) {
          const file = e.target.files[i];
          if (file?.name.includes(".png") || file?.name.includes(".jpg") || file?.name.includes(".jpeg")) {
            let base64 = await convertToBase64(file);
            base64 = base64.split(",")[1];
            base64images.push(base64);
          } else {
            setIsInvalid(true);
            break;
          }
        }
        if (base64images.length > 0) {
        //   setDataType(2);
          setIsLoading(true);
          setPlaceholder(translation?.Sending)
          axiosPost(`${API_BASE_URL}/UploadImages`, { img_data: base64images[0] }).then((response) => {

            const currentDate = new Date();
            const options = { 
              weekday: 'short', 
              month: 'short', 
              day: 'numeric', 
              year: 'numeric', 
              hour: '2-digit', 
              minute: 'numeric', 
              second: 'numeric', 
              timeZoneName: 'short', 
              timeZone: 'Asia/Kolkata',
              hour12: false // Set hour12 to false for 24-hour format
            };
        
            const formattedDate = currentDate.toLocaleString('en-US', options).replace(/,/g, '');
            const payload = {
              Authorization: JSON.parse(sessionStorage.getItem("loginToken"))?.idToken,
              ClassID: `PC${cls?.ID}`,
              DataType: "2", // 1 is for text 2 for image 3 is for doc if video then 4
              Message: response.data.Data,
              Person: profileInfo?.UserName,
              TutorID: participantList[0]?.ID,
              UserPic: profileInfo?.ProfilePictureUrl,
              UserUID: profileInfo?.UID,
              StudentID: profileInfo?.ID,
              Time: formattedDate,
            }
        
            socket.emit('YasaChat', JSON.stringify(payload));
            setIsLoading(false);
            setPlaceholder(translation?.WriteYourMessage);
          });
        }
    };

    // const fetchParticipantList = () => {
    //     const data = { ClassID: cls.ID };
    //     axiosPost(`${API_BASE_URL}/GetParticipanetsList`, data).then((response) => {
    //         if (response.data.Data) {
    //             const list = response.data.Data.filter((user) => user?.ID !== profileInfo?.ID)
    //             setParticipantList(list);
    //         }
    //     });
    // };

    // const fetchChatBackup = () => {
    //     const data = {
    //       ClassID: `PC${cls.ID}`,
    //     };
    //     axiosPost(`${API_BASE_URL}/GetChatdata`, data).then((res) => {
    //       setChatEvents(res.data.Data);
    //     });
    // };


    
    useEffect(() => {
        // fetchChatBackup();
        // fetchParticipantList();

        function onConnect() {
            socket?.emit("join", `${cls?.ID}`);
        }
      
        function onChatEvent(payload) {
            const responseData = JSON.parse(payload);
            const currentDate = new Date();
            const options = { 
              weekday: 'short', 
              month: 'short', 
              day: 'numeric', 
              year: 'numeric', 
              hour: '2-digit', 
              minute: 'numeric', 
              second: 'numeric', 
              timeZoneName: 'short', 
              timeZone: 'Asia/Kolkata',
              hour12: false // Set hour12 to false for 24-hour format
            };
            
            const formattedDate = currentDate.toLocaleString('en-US', options).replace(/,/g, '');
            setChatEvents((previous) => [
              ...previous,
              {
                Person: responseData?.Person,
                Message: responseData?.Message,
                Time: formattedDate,
                UserUID: responseData?.UserUID,
                UserPic: responseData?.UserPic,
                DataType: responseData?.DataType,
              },
            ]);
        }
      
        socket?.on("connect", onConnect());
        socket?.on("YasaChat", onChatEvent);
      
        return () => {
            socket?.off("connect", onConnect());
            socket?.off("YasaChat", onChatEvent);
        };
    }, []);

    useEffect(() => {
        if (Array.isArray(chatBackup)) {
            setChatEvents(chatBackup)
        }

    }, [chatBackup])

    useEffect(() => {
        if (Array.isArray(participantLists)) {
            const list = participantLists.filter((user) => user?.ID !== profileInfo?.ID)
            setParticipantList(list)
        }

    }, [participantLists])

  return (
    <>
        <Snackbar
            open={isInvalid}
            autoHideDuration={3000}
            onClose={() => setIsInvalid(false)}
            message={translation?.InvalidFile}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        />

        <Box className="chat_wrapper_parent">
            <Box className="chat_wrapper">
                <Box className="chat_left">
                    <Typography sx={{ color: '005DB8', marginBottom: '16px', fontSize: '18px', fontWeight: '600', color: '#727272' }}>chats(2)</Typography>
                    <Paper
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', marginBottom: '16px', height: '40px', boxShadow: 'none', borderRadius: '8px' }}
                    > <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search Here"
                            inputProps={{ 'aria-label': 'Search Here' }}
                        />

                    </Paper>
                    <Box className="read_label">
                        <Typography variant='span' className='active'>{translation?.All}</Typography>
                        <Typography variant='span'>{translation?.Unread}</Typography>
                    </Box>
                    <Box className="msg_box_wrapper">
                        { participantList.map((user) => {
                            return (
                                <Box className="msg_box" key={user.ID}>
                                    <img src={user?.ProfilePictureUrl} />
                                    <Box>
                                        <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#000000', textTransform: 'capitalize', marginBottom: '3px', lineHeight: '1' }}>{user?.UserName}</Typography>
                                        <Typography sx={{ fontSize: '12px', fontWeight: '500', color: '#555555', textTransform: 'capitalize', lineHeight: '1' }}>can yu explain me again ?</Typography>
                                    </Box>
                                    <Typography variant='span' sx={{ minWidth: '20px', width: '20px', height: '20px', backgroundColor: '#EAF4FF', color: '#005DB8', borderRadius: '50%', fontSize: '14px', fontWeight: '500', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '10px' }}>1</Typography>
                                </Box>
                            )
                        }) }
                        {/* <Box className="msg_box">
                            <img src={msgUser1} />
                            <Box>
                                <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#000000', textTransform: 'capitalize', marginBottom: '3px', lineHeight: '1' }}>arlene</Typography>
                                <Typography sx={{ fontSize: '12px', fontWeight: '500', color: '#555555', textTransform: 'capitalize', lineHeight: '1' }}>can yu explain me again ?</Typography>
                            </Box>
                            <Typography variant='span' sx={{ minWidth: '20px', width: '20px', height: '20px', backgroundColor: '#EAF4FF', color: '#005DB8', borderRadius: '50%', fontSize: '14px', fontWeight: '500', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '10px' }}>1</Typography>
                        </Box>
                        <Box className="msg_box">
                            <img src={msgUser1} />
                            <Box>
                                <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#000000', textTransform: 'capitalize', marginBottom: '3px', lineHeight: '1' }}>arlene</Typography>
                                <Typography sx={{ fontSize: '12px', fontWeight: '500', color: '#555555', textTransform: 'capitalize', lineHeight: '1' }}>can yu explain me again ?</Typography>
                            </Box>
                            <Typography variant='span' sx={{ minWidth: '20px', width: '20px', height: '20px', backgroundColor: '#EAF4FF', color: '#005DB8', borderRadius: '50%', fontSize: '14px', fontWeight: '500', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '10px' }}>1</Typography>
                        </Box>
                        <Box className="msg_box">
                            <img src={msgUser1} />
                            <Box>
                                <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#000000', textTransform: 'capitalize', marginBottom: '3px', lineHeight: '1' }}>arlene</Typography>
                                <Typography sx={{ fontSize: '12px', fontWeight: '500', color: '#555555', textTransform: 'capitalize', lineHeight: '1' }}>can yu explain me again ?</Typography>
                            </Box>
                            <Typography variant='span' sx={{ minWidth: '20px', width: '20px', height: '20px', backgroundColor: '#EAF4FF', color: '#005DB8', borderRadius: '50%', fontSize: '14px', fontWeight: '500', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '10px' }}>1</Typography>
                        </Box>
                        <Box className="msg_box">
                            <img src={msgUser1} />
                            <Box>
                                <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#000000', textTransform: 'capitalize', marginBottom: '3px', lineHeight: '1' }}>arlene</Typography>
                                <Typography sx={{ fontSize: '12px', fontWeight: '500', color: '#555555', textTransform: 'capitalize', lineHeight: '1' }}>can yu explain me again ?</Typography>
                            </Box>
                            <Typography variant='span' sx={{ minWidth: '20px', width: '20px', height: '20px', backgroundColor: '#EAF4FF', color: '#005DB8', borderRadius: '50%', fontSize: '14px', fontWeight: '500', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '10px' }}>1</Typography>
                        </Box> */}
                    </Box>
                </Box>
                <Box className="chat_right">
                    <Box className="chat_head">
                        <img src={profileInfo?.ProfilePictureUrl} width="10%" />
                        <Box className="">
                            <Typography sx={{ fontSize: '14px', color: '#000000', fontWeight: '500', lineHeight: '1', marginBottom: '3px', textTransform: 'capitalize' }}>{profileInfo?.UserName}</Typography>
                            <Typography sx={{ fontSize: '12px', color: '#555555', fontWeight: '500', lineHeight: '1' }}>last seen 1 hr ago</Typography>
                        </Box>
                    </Box>
                    <Box className="messages_box">

                        { chatEvents?.map((chat, index) => {
                            return (
                                <Box className={`main_chat ${chat?.UserUID == profileInfo?.UID ? 'right_chat' : 'left_chat'}`} key={index}>
                                    { chat.DataType === '1' ?
                                        <Typography className='para_chat'>{chat?.Message}</Typography> :
                                        <Box className="para_chat_imgs" style={{ cursor: 'pointer' }}>
                                            <img src={chat?.Message} onClick={() => openImage(chat?.Message)} width="40%" />
                                            {/* <img src={chat_img} /> */}
                                        </Box>
                                    }
                                    <Typography className='time_chat'>{new Date(chat?.Time).toLocaleDateString('en-US')}</Typography>
                                </Box>
                            )
                        }) }
                        {/* <Box className="main_chat right_chat">
                            <Typography className='para_chat'>hello how are you ?</Typography>
                            <Typography className='time_chat'>1 hour ago</Typography>
                        </Box>
                        <Box className="main_chat left_chat">
                            <Typography className='para_chat'>i have some doubt</Typography>
                            <Box className="para_chat_imgs">
                                <img src={chat_img} />
                                <img src={chat_img} />
                            </Box>
                            <Typography className='time_chat'>30 min ago</Typography>
                        </Box>
                        <Box className="main_chat right_chat">
                            <Typography className='para_chat'>ok</Typography>
                        </Box> */}
                    </Box>
                    <Box className="chat_input">
                        <Box className='chat_input_inner_wrap'>
                            <form onSubmit={onSubmit}>
                                <TextField
                                    id="outlined-textarea"
                                    placeholder={placeholder}
                                    value={value}
                                    onChange={handleOnChange}
                                />
                                <Box className="upload_send_wrap">
                                    <Box className="upload_wrap">
                                        <input 
                                            type="file" 
                                            className='upload_file'
                                            onChange={handleFileInput}
                                            accept=".png, .jpg, .jpeg"
                                        />
                                        <img src={attachFile} />
                                    </Box>
                                    <Button type="submit" disabled={isLoading} className="send_btn"><img src={sendAero} /></Button>
                                </Box>
                            </form>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    </>
  )
}

export default React.memo(Chat);

// Function to open the image in a modal
export const openImage = (imageSrc) => {
    const modal = document.getElementById("zoomImageModal");
    const modalImage = document.getElementById("modalImage");
    modal.style.display = "block";
    modalImage.src = imageSrc;
  };
  
// Function to close the modal
export const closeImage = () => {
    const modal = document.getElementById("zoomImageModal");
    modal.style.display = "none";
};

export const ZoomImageContainer = () => {
    return (
        <div id="zoomImageModal" className="zoom-image-modal">
            <span className="close" onClick={closeImage}>
            &times;
            </span>
            <img className="zoom-image-modal-content" id="modalImage" />
        </div>
    );
};