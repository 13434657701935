import { useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { WebSocketURL } from '../config/apiConfig';
import { getHelperData } from '../actions/getHelperData';
import { getExamBoards } from '../actions/getExamBoardsAction';

let socket; // Declare the socket variable outside the hook
const useSocket = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isConnected, setIsConnected] = useState(false);
  const [isEnvironmentSet, setIsEnvironmentSet] = useState(false);

  const helperData = useSelector(({ helperDataReducer }) => helperDataReducer?.helperData);
  const boards = useSelector(({ examBoardsReducer }) => examBoardsReducer?.boards);

  useEffect(() => {
    // Create the socket instance only once when the component mounts
    socket = io(WebSocketURL,{
      forceNew: true,
      transports: ['websocket', 'polling'],
      reconnection: true,
      reconnectionAttempts: 3, // Number of attempts
      reconnectionDelay: 1000, // Delay between attempts in milliseconds
    });

    // Listen for connection and disconnection events
    socket.on('connect', () => {
      console.log('Socket Connected')
      setIsConnected(true);
    });

    socket?.on('CountryEvent', (data) => {
      const response =  JSON.parse(data);
      const ID = response?.Data[0]?.ID
      const payload = {
        "RoleID" : "3",
        "Platform" : "Web",
        "AppVersion" : "1.0"
      }
      localStorage.setItem('CountryID', ID)
      socket.emit('AppConfiguration', JSON.stringify(payload))
    })

    socket?.on('AppConfiguration', (appConfigData) => {
      const appConfigResponse =  JSON.parse(appConfigData);
      if (appConfigResponse?.Data?.Environment) {
        localStorage.setItem('Environment', appConfigResponse?.Data?.Environment)
        setIsEnvironmentSet(true);
      }
    })

    socket?.on('ClientActiveStatus', (response) => {
      const profile = JSON.parse(sessionStorage.getItem('ProfileInfo'));
      if (profile) {
        const payload = {};
        payload['UserID'] = profile?.ID?.toString();
        socket?.emit('ClientResponse', JSON.stringify(payload));
      }
    })

    socket?.on('LoginSessionResponseEvent', (data) => {
      const loginSessionResponse = JSON.parse(data);
      console.log('loginSessionResponse: ', loginSessionResponse);
      const deviceId = sessionStorage.getItem("deviceId");
      if (loginSessionResponse?.Data?.DeviceID !== deviceId) {
        sessionStorage.setItem('IsActive', 'false');
        localStorage.setItem('customToken', loginSessionResponse?.Data?.AuthToken);
        navigate('/multi-device');
      }
    });

    socket.on('disconnect', () => {
      console.log('Socket Disconnected')
      setIsConnected(false);
    });

    socket.on('connect_error', (error) => {
      console.error('Socket Connection Error:', error);
      setIsConnected(false);
    });

    // Clean up the socket instance and event listeners when the component unmounts
    // return () => {
    //   socket.disconnect();
    //   socket.off('connect');
    //   socket.off('disconnect');
    // };
  }, []);

  useEffect(() => {
    // Call API functions only once when environment is set
    if (isEnvironmentSet && !helperData) {
      dispatch(getHelperData());
    }

    if (isEnvironmentSet && !boards) {
      dispatch(getExamBoards());
    }
  }, [isEnvironmentSet, dispatch]);

  return { socket, isConnected };
}

export default useSocket;
