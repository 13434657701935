import React, { useState, useEffect } from 'react'
import { Box, Typography, RadioGroup, FormControlLabel, Radio, OutlinedInput } from '@mui/material'
import Button from '@mui/material/Button';
import Header from "../header/Header";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import TextField from '@mui/material/TextField';

import FooterSection from "../footer/FooterSection";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import "../../App.css";
import "../subject/Subject.css";
import "../registration/Registration.css";
import "../private-class/PrivateClass.css";
import "../overview/Overview.css";
import "../responsive/Responsive.css";
import "./UserProfile.css";

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';

import useTranslations from '../../custom-hooks/useTranslations';
import useHelperData from '../../custom-hooks/useHelperData';
import { getAppLanguage } from '../../actions/getAppLanguageAction';
import { getStudentProfile } from '../../actions/studentProfileAction';
import { updateStudentProfile } from '../../actions/updateStudentProfileAction';
import { getExamBoards } from '../../actions/getExamBoardsAction';

import Advertisement from '../common-components/Advertisement';

const UserProfile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const translations = useTranslations();
    const { helperData } = useHelperData();
    const [tab, setTab] = useState(0);
    const [selectedBoard, setSelectedBoard] = useState({});
    const profileInfo = JSON.parse(sessionStorage.getItem("ProfileInfo"));

    const translation = {
        ...translations[0]?.Login,
        ...translations[2]?.EditProfile,
        ...translations[21]?.CommonFrontend,
        ...translations[26]?.QuickCall,
        ...translations[33]?.ApplicationConfiguration
    }

    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            UserName: profileInfo?.UserName,
            Phone: profileInfo?.Phone,
            Email: profileInfo?.Email,
            GenderID: profileInfo?.GenderID,
            StudentExaminationID: profileInfo?.StudentExaminationID,
            StudentSubExaminationID: profileInfo?.StudentSubExaminationID,
            StudentTargetExamID: profileInfo?.StudentTargetExamID,
            StudentGradeID: profileInfo?.StudentGradeID,
            AppLanguageID: profileInfo?.AppLanguage,
            TeachingLanguageID: profileInfo?.TeachingLanguage
        },
        mode: 'onChange'
    });



	const boards = useSelector(({ examBoardsReducer }) => examBoardsReducer?.boards);
    // const helperData = useSelector(({ helperDataReducer }) => helperDataReducer?.helperData);
    const studentProfile = useSelector(({ studentProfileReducer }) => studentProfileReducer.student);
	const appLanguage = useSelector(({ AppLanguageReducer }) => AppLanguageReducer.applanguage);

    const handleBack = () => {
        navigate(-1);
    }

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleExamBoardSelect = (e) => {
        const selectExamBoard = boards?.find((examItem) => examItem.ID === Number(e.target.value))
		setSelectedBoard(selectExamBoard);
	};

    const onSubmit = (data) => {
		const obj = {
			"UserName": data.UserName,
			"email": profileInfo?.Email,
			"StudentGenderID": data.GenderID,
			"StudentGradeID": data.StudentGradeID,
			"StudentExaminationID": data.StudentExaminationID,
			"StudentTargetExamID": data.StudentTargetExamID,
			"StudentSubExaminationID": data.StudentSubExaminationID,
			"AppLanguage": data.AppLanguageID,
			"TeachingLanguage": data.TeachingLanguageID
		}

		dispatch(updateStudentProfile(obj))
	}

    useEffect(() => {
		if (boards && studentProfile) {
			const selectExamBoard = boards?.find((examItem) => examItem.ID === Number(studentProfile[0]?.StudentExaminationID))
			setSelectedBoard(selectExamBoard)
		}

	}, [boards, studentProfile])

    useEffect(() => {
        if (!boards) {
            dispatch(getExamBoards());
        }

    }, [boards])

    useEffect(() => {
		dispatch(getStudentProfile());
		dispatch(getAppLanguage());
	}, [dispatch]);

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: '50%',
        width: 16,
        height: 16,
        border: '1px solid #F5F5F5',

        backgroundColor: '#F5F5F5',
        'input:disabled ~ &': {
            boxShadow: 'none',
            background:
                theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
    }));

    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#f5f5f5',
        '&::before': {
            display: 'block',
            width: '8px',
            height: '8px',
            content: '""',
            backgroundColor: '#0F4D92',
            borderRadius: '100%',
            top: '4.2px',
            left: '4.2px',
            position: 'absolute',
        },
    });

    const BpRadio = (props) => {
        return (

            <Radio
                disableRipple
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                {...props}
            />
        );
    }

    return (
        <div>
            <Header />
            <Box className="quikCallBanner">
                <Box className='OuterContainer' sx={{ border: 'none', borderRadius: '0px' }}>
                    <Typography variant='h1'>
                        {translation?.Hi} {profileInfo?.UserName}, <Typography variant='div'> {translation?.WelcomeYasa}</Typography>
                    </Typography>
                </Box>
            </Box>
            <Box className='OuterContainer containerShape' sx={{ background: '#EAF4FF', padding: '30px' }}>
                <Box className='SectionIn'>
                    <Box className="BackBtn">
                        <Button className='backBtn' variant="text" sx={{ mb: 2 }} startIcon={localStorage.getItem("I18N_LANGUAGE") === "en" ? <ArrowBackIcon /> : <ArrowForward />} onClick={handleBack}> {translation?.ProfileBackBtn} </Button>
                    </Box>
                    <Box sx={{ width: '100%' }} className="tab-wrapper">
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example" variant="scrollable"
                                allowScrollButtonsMobile>
                                <Tab label={translation?.PersonalInfo} {...a11yProps(0)} />
                                <Tab label={translation?.AcademicInfo} {...a11yProps(1)} />
                                <Tab label={translation?.LanguagePreference} {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <Box className="custom-panel-wrapper user-tab-wrapper">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                { tab === 0 && <Box sx={{ p: 3}}>
                                    <Typography sx={{ fontSize: '18px', fontWeight: '500', color: '#000', marginBottom: '16px', textTransform: 'capitalize' }}>
                                        {translation?.PersonalInfo}
                                    </Typography>
                                    <Box className="inpFlex">
                                    <Box>
                                        <Controller
                                            name="UserName"
                                            control={control}
                                            rules={{ required: translation?.NameIs }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label={translation?.Name}
                                                    placeholder={translation.Name}
                                                    error={!!errors.UserName}
                                                />
                                            )}
                                        />
                                        {errors.UserName && <small className="text-danger">{errors.UserName.message}</small>}
                                    </Box>

                                        {profileInfo?.Phone &&  
                                            <Box>
                                                <Controller
                                                    name="Phone"
                                                    control={control}
                                                    rules={{ 
                                                        required: translation?.PhoneNumberIs,
                                                        pattern: { value: /^[0-9]+$/, message: translation?.PhoneNumberMust }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            placeholder={translation?.MobileNum}
                                                            label={translation?.MobileNum}
                                                            error={!!errors.Phone}
                                                            // inputProps={{ pattern: '[0-9]*', inputMode: 'numeric', readOnly: !!profileInfo.Phone }}
                                                            inputProps={{ pattern: '[0-9]*', inputMode: 'numeric' }}
                                                        />
                                                    )}
                                                />
                                                {errors.Phone && <small className="text-danger">{errors.Phone.message}</small>}
                                            </Box>
                                        }

                                        { profileInfo?.Email && 
                                            <Box>
                                                <Controller
                                                    name="Email"
                                                    control={control}
                                                    rules={{ 
                                                        required: translation?.EmailIs,
                                                        pattern: {
                                                            value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                                                            message: translation?.ValidEmail
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            placeholder={translation?.Email}
                                                            label={translation?.Email}
                                                            error={!!errors.Email}
                                                            inputProps={{ readOnly: !!profileInfo.Email }}
                                                        />
                                                    )}
                                                />
                                                {errors.Email && <small className="text-danger">{errors.Email.message}</small>}
                                            </Box>
                                        }
                                    </Box>

                                    <Controller
                                        name="GenderID"
                                        control={control}
                                        rules={{ required: translation?.GenderIs }}
                                        render={({ field }) => (
                                            <RadioGroup
                                                {...field}
                                                aria-labelledby="demo-customized-radios"
                                                name="customized-radios"
                                                className="personalRadio"
                                            >
                                                {helperData?.Gender?.map((e) => (
                                                    <FormControlLabel key={e.ID} value={e.ID} control={<BpRadio />} label={e.Value} />
                                                ))}
                                            </RadioGroup>
                                        )}
                                    />
                                    {errors.GenderID && <small className="text-danger">{errors.GenderID.message}</small>}

                                    <Button type="submit" className="blueBtn saveBtn">{translation?.Save}</Button>

                                </Box> }

                                { tab === 1 && <Box sx={{ p: 3}}>
                                    <Typography sx={{ fontSize: '18px', fontWeight: '500', color: '#000', marginBottom: '16px', textTransform: 'capitalize' }}>
                                        {translation?.AcademicInfo}
                                    </Typography>
                                    <Box className='personalSelect academicSelect'>
                                        <FormControl fullWidth error={!!errors.StudentExaminationID}>
                                            <InputLabel id="exam-label">{translation?.ExamBoard}</InputLabel>
                                            <Controller
                                                name="StudentExaminationID"
                                                control={control}
                                                rules={{ required: translation?.ExamBoardIs }}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        labelId="exam-label"
                                                        id="exam"
                                                        value={field.value}
                                                        label={translation?.ExamBoard}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            handleExamBoardSelect(e);
                                                        }}
                                                    >
                                                        {boards?.map((examItem) => (
                                                            <MenuItem key={examItem.ID} value={examItem?.ID}>
                                                                {examItem?.Value}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                            {errors.StudentExaminationID && <small className="text-danger">{errors.StudentExaminationID.message}</small>}
                                        </FormControl>

                                        {selectedBoard?.SubBoards?.length > 0 && (
                                            <FormControl fullWidth error={!!errors.StudentSubExaminationID}>
                                                <InputLabel id="sub-exam-label">{translation?.SubExam}</InputLabel>
                                                <Controller
                                                    name="StudentSubExaminationID"
                                                    control={control}
                                                    rules={{ required: translation?.SubExamBoardIs }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            labelId="sub-exam-label"
                                                            id="sub-exam"
                                                            value={field.value}
                                                            label={translation?.SubExam}
                                                            onChange={(e) => field.onChange(e)}
                                                        >
                                                            {selectedBoard?.SubBoards.map((subBoardItem) => (
                                                                <MenuItem key={subBoardItem.ID} value={subBoardItem.ID}>{subBoardItem.Value}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    )}
                                                />
                                                {errors.StudentSubExaminationID && <small className="text-danger">{errors.StudentSubExaminationID.message}</small>}
                                            </FormControl>
                                        )}

                                        <FormControl fullWidth error={!!errors.StudentTargetExamID}>
                                            <InputLabel id="target-exam-label">{translation?.TargetExam}</InputLabel>
                                            <Controller
                                                name="StudentTargetExamID"
                                                control={control}
                                                rules={{ required: translation?.TargetExamIs }}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        labelId="target-exam-label"
                                                        id="target-exam"
                                                        value={field.value}
                                                        label="Target Exam"
                                                        onChange={(e) => field.onChange(e)}
                                                    >
                                                        {helperData?.TargetedExam?.map((target) => (
                                                            <MenuItem key={target.ID} value={target.ID}>
                                                                {target?.Value}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                            {errors.StudentTargetExamID && <small className="text-danger">{errors.StudentTargetExamID.message}</small>}
                                        </FormControl>

                                        <FormControl fullWidth error={!!errors.StudentGradeID}>
                                            <InputLabel id="grade-label">{translation?.Grade}</InputLabel>
                                            <Controller
                                                name="StudentGradeID"
                                                control={control}
                                                rules={{ required: translation?.GradeIs }}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        labelId="grade-label"
                                                        id="grade"
                                                        value={field.value}
                                                        label={translation?.Grade}
                                                        onChange={(e) => field.onChange(e)}
                                                    >
                                                        {selectedBoard?.Grades?.map((grade) => (
                                                            <MenuItem key={grade?.ID} value={grade.ID}>{grade.Value}</MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                            {errors.StudentGradeID && <small className="text-danger">{errors.StudentGradeID.message}</small>}
                                        </FormControl>
                                    </Box>
                                    <Button type="submit" className="blueBtn saveBtn">{translation?.Save}</Button>
                                </Box> } 

                                { tab === 2 && <Box sx={{ p: 3 }}>
                                    <Typography sx={{ fontSize: '18px', fontWeight: '500', color: '#000', marginBottom: '16px', textTransform: 'capitalize' }}>
                                        {translation?.LanguagePreference}
                                    </Typography>
                                    <Box className='personalSelect langPre'>
                                        <FormControl fullWidth error={!!errors.AppLanguageID}>
                                            <InputLabel id="app-language-label">{translation?.AppLanguage}</InputLabel>
                                            <Controller
                                                name="AppLanguageID"
                                                control={control}
                                                rules={{ required: translation?.AppLanguageIs }}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        labelId="app-language-label"
                                                        id="app-language"
                                                        label={translation?.AppLanguage}
                                                        value={field.value}
                                                        onChange={(e) => field.onChange(e)}
                                                    >
                                                        {appLanguage?.map((language) => (
                                                            <MenuItem key={language.ID} value={language.ID}>{language?.Value}</MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                            {errors.AppLanguageID && <small className="text-danger">{errors.AppLanguageID.message}</small>}
                                        </FormControl>

                                        <FormControl fullWidth error={!!errors.TeachingLanguageID}>
                                            <InputLabel id="teaching-language-label">{translation?.TeachingLanguage}</InputLabel>
                                            <Controller
                                                name="TeachingLanguageID"
                                                control={control}
                                                rules={{ required: translation?.TeachingLanguageIs }}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        labelId="teaching-language-label"
                                                        id="teaching-language"
                                                        label={translation?.TeachingLanguage}
                                                        value={field.value}
                                                        onChange={(e) => field.onChange(e)}
                                                    >
                                                        {appLanguage?.map((language) => (
                                                            <MenuItem key={language.ID} value={language.ID}>{language?.Value}</MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                            {errors.TeachingLanguageID && <small className="text-danger">{errors.TeachingLanguageID.message}</small>}
                                        </FormControl>
                                    </Box>
                                    <Button type="submit" className="blueBtn saveBtn">{translation?.Save}</Button>
                                </Box> }
                            </form>

                        </Box>
                    </Box>
                    <Advertisement />
                </Box>
            </Box>
            <FooterSection />
        </div>
    )
}

export default UserProfile
