import React, { useState, useEffect } from 'react'
import { Box, Typography, Alert, FormHelperText  } from '@mui/material'
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import "./Login.css";

import penguine from '../../assets/images/penguine.png';
import google from '../../assets/images/google_logo.svg';
import apple from '../../assets/images/apple_logo.svg';
import scan from '../../assets/images/scan.svg';
import msg2 from '../../assets/images/msg2.svg';
import whatsapp2 from '../../assets/images/whatsapp.svg';

import Timers from '../timer/Timers';
import LoginSlider from './LoginSlider';
import FlagDropDown from './FlagDropDown';
import ErrorModal  from "../common-components/ErrorModal";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import OtpInput from "react-otp-input";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { browserName, isBrowser, isTablet, isAndroid } from 'react-device-detect';

import useSocket from '../../custom-hooks/useSocket';
import useTranslations from '../../custom-hooks/useTranslations';
import { useUserAuth } from "../../context/UserAuthContext";
import { openModal } from "../../actions/modalAction";
import { getStudentProfile } from '../../actions/studentProfileAction';
import { getStudentDashBoard } from '../../actions/studentDashboardAction';

import { API_BASE_URL } from '../../config/apiConfig';
import { axiosGet, axiosPost } from '../../services/api';
import { generateDeviceId } from '../../services/common';

import QRCodeComponent from './QRCodeComponent';

const dialObj = {
  ar: [
    {
      "ID": 113,
      "Value": "الكويت",
      "DialCode": "+965",
      "CountryCode": "KW",
      "NumbersLimit": 8,
      "FlagLink": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KW.svg",
      "BankValidation": 10
    }
  ],
  en: [
    {
      "ID": 113,
      "Value": "Kuwait",
      "DialCode": "+965",
      "CountryCode": "KW",
      "NumbersLimit": 8,
      "FlagLink": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KW.svg",
      "BankValidation": 10
    }
  ]
}

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {socket, isConnected } = useSocket();
  const { t } = useTranslation();  
  const translations = useTranslations();
  const { logIn, googleSignIn } = useUserAuth();

  const [tab, setTab] = useState(0);
  const [dialCode, setDialCode] = useState(dialObj[localStorage.getItem("I18N_LANGUAGE")] || []);
  const [selectedDialCode, setSelectedDialCode] = useState("+965");
  const [country, setCountry] = useState({});
  const [countryEvent , setCountryEvent] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isApiCalling, setIsApiCalling] = useState(false);
  const [seconds, setSeconds] = useState(30);
  const [isWhatsApp, setIsWhatsApp] = useState(false);
  const [existedUser, setExistedUser] = useState(false);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [isResendOTPAlert, setIsResendOTPAlert] = useState(false);

  const [showQR, setShowQR] = useState(false);
  const [qRvalue, setQRvalue] = useState("");
	const [connectId, setConnectId] = useState();

  const { handleSubmit, control, formState: { errors }, getValues, setValue, clearErrors } = useForm({ defaultValues: {
    phoneNumber: ""
  },
    mode: 'all'
 });

  const translation = { 
    ...translations[0]?.Login, 
    ...translations[1]?.OtpVerification, 
    ...translations[2]?.EditProfile, 
    ...translations[26]?.QuickCall,
    ...translations[33]?.ApplicationConfiguration
  } 

  const helperData = useSelector(({ helperDataReducer }) => helperDataReducer?.helperData);
  const isOpenModal = useSelector(({ modalReducer }) => modalReducer.openModal);

  const inputStyle = {
    width: "48px",
    height: "48px",
    // margin: "0px 5px",
    background: "#F5F5F5",
  };

  const handleOtpChange = (newValue) => {
		setOtp(newValue);
  }

  const handleDialCodeChange = (event) => {
    const { value } = event.target;
    setSelectedDialCode(value);
    const selectedCountry = dialCode.find((item) => item?.DialCode === value);
    
    if (selectedCountry) {
      setCountry(selectedCountry);
      setValue('phoneNumber', getValues('phoneNumber')?.slice(0, selectedCountry.NumbersLimit) || '')
      setPhoneNumber((prev) => prev.slice(0, selectedCountry.NumbersLimit));
    }
    clearErrors("phoneNumber");
  };

  const handlePhoneNumberChange = (e) => {
    const inputValue = e.target.value;
    if (/^[0-9]*$/.test(inputValue) && inputValue.length <= country.NumbersLimit) {
      setPhoneNumber(inputValue);
    }
  };

  // const sendOtpHandler = async (e) => {
  //   e.preventDefault();
  const sendOtpHandler = async (data) => {
    setIsApiCalling(true);
    const number = selectedDialCode + phoneNumber.trim();
    axiosPost(`${API_BASE_URL}/IsRegistered`, { userNumber: number }).then((res) => {
      localStorage.setItem("PhoneNumber", number)
      const registeredResponse = res?.data?.Data;
      setIsApiCalling(false);
      setExistedUser(registeredResponse?.IsRegistered);

      if (registeredResponse?.IsRegistered && registeredResponse?.RoleID !== 3) {
        dispatch(openModal(translation?.PleaseLoginStudentAC || t('PleaseLoginStudentAC')));
      } else {
        sentOtp(number)
      }
    }).catch((error) => {
      setIsApiCalling(false);
      console.log('Error: ', error);
    })
  };

  const sentOtp = async (number) => {
    try {
      setTab(1);
      setSeconds(30);
      const header = {
        "Content-Type": "application/json"
      };
      const data = { 
        PhoneNumber: number,
        OnWhatsapp: isWhatsApp
      }

      await axiosPost(`${API_BASE_URL}/SendOTP`, data, { headers: header });
    }
    catch (e) {
      console.log("Error in login: ", e)
    }
  }

  const verifyOtp= async (e) => {
    e.preventDefault();
    setError("");
    if (otp === "" || otp === null) return;
    try {
      setIsApiCalling(true);
      const number = selectedDialCode + phoneNumber;
      const myid = `${number.slice(1)}@getyasa.com`
      const res = await logIn (myid, otp)
      // setIsApiCalling(false);
      res._tokenResponse.expireTime = Date.now();

      sessionStorage.setItem("userInfo", JSON.stringify(res.user));
      sessionStorage.setItem("loginToken", JSON.stringify(res._tokenResponse));

      if (existedUser) {
        sessionSave(res.user.uid);
      } else {
        navigate('/registration')
      }

    } catch (err) {
      setIsApiCalling(false);
      setError(err.message);
    }
  };

  const sessionSave = async (uid) => {
    // setIsApiCalling(true);
    const deviceId = generateDeviceId();
    sessionStorage.setItem("deviceId", deviceId)

    const data = {
      UserUID: uid,
      Maker: navigator.platform,
      Model: navigator.userAgent.split(" ")[0],
      DeviceID: deviceId,
      Platform: "Web",
      AppVersion: "1.0",
    };
    axiosPost(`${API_BASE_URL}/SaveLoginSession`, data)
      .then((res) => {
        setIsApiCalling(false);
        const customToken  = res.data.Token;
        sessionStorage.removeItem('IsActive');
        localStorage.setItem('customToken', customToken);

        const mySocketData = {
          AuthToken: customToken,
          authorization: JSON.parse(sessionStorage.getItem('loginToken')).idToken,
          DeviceID: deviceId,
          IsActive: "true"
        }
        console.log('mySocketData: ', mySocketData);
        socket.emit("LoginSession", JSON.stringify(mySocketData));

        const payload = {
          authorization: JSON.parse(sessionStorage.getItem('loginToken')).idToken,
          DeviceID: deviceId,
        }
        socket.emit('StudentOnline', JSON.stringify(payload));

        dispatch(getStudentProfile());
				dispatch(getStudentDashBoard());
        navigate("/");
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const changeNumber = () => {
		setTab(0);
    setOtp("");
    setError("");
    setIsResendOTPAlert(false);
	};

  const onResendOTPClick = () => {
    setTab(2);
		setOtp("");
		setError("");
    setIsWhatsApp(true);
		setIsResendOTPAlert(false);
	}

  const handleResendOTP = () => {
    const number = selectedDialCode + phoneNumber.trim();
    sentOtp(number);
  }

  const handleGoogleSignIn = async() => {
    try {
      const response = await googleSignIn();
      setIsApiCalling(true);

      sessionStorage.setItem("userInfo", JSON.stringify(response.user));
      sessionStorage.setItem("loginToken", JSON.stringify(response?._tokenResponse));
      axiosPost(`${API_BASE_URL}/IsRegistered`, { userEmail: response.user.email }).then((res) => {
        localStorage.setItem("PhoneNumber", response.user.email)
        const registeredResponse = res?.data?.Data;
        // setIsApiCalling(false);

        if (registeredResponse?.IsRegistered === false) {
          navigate('/registration')
        } else if (registeredResponse?.IsRegistered && registeredResponse?.RoleID !== 3) {
          setIsApiCalling(false);
          dispatch(openModal(translation?.PleaseLoginStudentAC || t('PleaseLoginStudentAC')));
        } else {
          sessionSave(response.user.uid);
        }

      })
    } catch (e) {
      console.log('e: ', e);
      setIsApiCalling(false);
    }
  }

  const getQRcode = () => {
    axiosGet(`${API_BASE_URL}/GenerateQR`)
      .then((res) => {
        setQRvalue(res.data.Code);
        setConnectId(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (helperData) {
      setDialCode(helperData?.Country || []);
      const selectedCountry = helperData?.Country.find((countryItem) => countryItem?.ID.toString() === localStorage.getItem('CountryID'))
      if (selectedCountry !== undefined) {
        setCountry(selectedCountry)
        setSelectedDialCode(selectedCountry?.DialCode)
      } else {
        setSelectedDialCode("+965")
        setCountry(helperData?.Country.find((countryItem) => countryItem?.ID.toString() === '61') || {});
      }
    }
    
  }, [helperData])

  const countryEventResponse = () => {
    socket?.on('CountryEvent', (data) => {
      const response =  JSON.parse(data);
      const obj = {};
      obj['StatusCode'] = response?.StatusCode;
      obj['IsActiveStudent'] = response?.Data[0]?.IsActiveStudent;
      setCountryEvent(obj);
    })
  }

  useEffect(() => {
    isConnected && countryEventResponse();

  }, [isConnected]);

  useEffect(() => {
    getQRcode();
    if (!isBrowser && !isTablet) {
    	if (isAndroid) {
    		window.location.href = 'https://play.google.com/store/apps/details?id=yasa.student&hl=en_IN&gl=US';
    	} else {
    		window.location.href = 'https://onelink.to/39wfhz';
    	}
    } 

    if (browserName !== 'Chrome') {
      dispatch(openModal(translation?.WeRecommendChrome || t('WeRecommendChrome')))
    }

  }, [])
  
  return (
    countryEvent?.StatusCode === 403 || countryEvent?.StatusCode === 503 || countryEvent?.IsActiveStudent === false ? 
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
      <Box className="mb-20">
        <h2 className="title2">{countryEvent?.StatusCode === 403 || countryEvent?.IsActiveStudent === false ? translation?.AppRistrictMessage || t('AppRistrictMessage') : translation?.AppUnderMaintenanceMsg || t('AppUnderMaintenanceMsg')}</h2>
      </Box>
    </Box> : <Box className='login_wrapper'>
      <LoginSlider />
      { tab === 0 &&
        <Box className="right_section">
        { showQR ? <QRCodeComponent qRvalue={qRvalue} connectId={connectId} setShowQR={setShowQR} sessionSave={sessionSave} /> :
          <form onSubmit={handleSubmit(sendOtpHandler)}>
          {/* <form onSubmit={sendOtpHandler}> */}
            <Box className="login_form">
              <Typography variant="h3" sx={{ fontSize: '24px', color: '#0F4D92', fontWeight: '600', marginBottom: '8px', textTransform: 'capitalize' }}>{translation?.Login || t('Login')}</Typography>
              <Typography variant="p" sx={{ fontSize: '18px', color: '#555555', fontWeight: '500', marginBottom: '32px' }}>{translation?.WeWillSendCode || t('WeWillSendCode')}</Typography>
              <Box className="sel_inp_wrap" sx={{ direction: 'ltr' }}>
                {/* <FormControl className="custom-formControl">
                  <TextField
                    placeholder={translation?.EnterMobileNumber || t('EnterMobileNumber')}
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    required
                  />
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedDialCode}
                    onChange={handleDialCodeChange}
                  >
                    {dialCode?.map((code) => (
                      <MenuItem key={code?.ID} value={code?.DialCode}>
                        {code?.DialCode}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}

                <FormControl className="custom-formControl">
                  <Controller
                    name="phoneNumber"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: translation?.PhoneNumberIs,
                      // pattern: {
                      //   value: /^[0-9]*$/,
                      //   message: "Phone number must contain only digits"
                      // },
                      minLength: {
                        value: country.NumbersLimit,
                        message: translation?.PhoneNumberMust?.replace('8', country.NumbersLimit)
                      },
                      // maxLength: {
                      //   value: country.NumbersLimit,
                      //   message: `Phone number cannot exceed ${country.NumbersLimit} digits`
                      // }
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder={translation?.EnterMobileNumber || t('EnterMobileNumber')}
                        error={!!errors.phoneNumber}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          // field.onChange(inputValue);
                          // setPhoneNumber(inputValue);
                          if (/^[0-9]*$/.test(inputValue) && inputValue.length <= country.NumbersLimit) {
                            field.onChange(inputValue);
                            setPhoneNumber(inputValue);
                          }
                        }}
                        // helperText={errors.phoneNumber?.message}
                      />
                    )}
                  />
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedDialCode}
                    onChange={handleDialCodeChange}
                  >
                    {dialCode?.map((code) => (
                      <MenuItem key={code?.ID} value={code?.DialCode}>
                        {code?.DialCode}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                { errors.phoneNumber && (
                  <FormHelperText sx={{color: '#EA4435;', mt: 1}}>{errors.phoneNumber.message}</FormHelperText>
                )}
              </Box>
              <Box className="or_box">
                <Typography variant='span' sx={{ fontSize: '14px', color: '#727272', backgroundColor: '#fff', margin: '0 16px', position: 'relative' }}>{translation?.Or || t('Or')}</Typography>
              </Box>
              { (JSON.parse(translation?.EnableGoogleLoginWebStudent || 'false') || JSON.parse(translation?.EnableAppleLoginWebStudent || 'false')) && <>
                <Typography variant='h5' sx={{ fontSize: '16px', fontWeight: '500', marginBottom: '10px' }}>{translation?.ConnectWith || t('ConnectWith')}</Typography>
                <Box className="btn_flex" sx={{ marginBottom: '16px' }}>
                  { JSON.parse(translation?.EnableGoogleLoginWebStudent || 'false') &&
                    <Button className='linking_btn'>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: '500', color: '#555555' }} onClick={handleGoogleSignIn}>{translation?.GoogleAccount || t('GoogleAccount')}</Typography>
                        <img src={google} alt="google" />
                      </Box>
                    </Button>
                  }
                  { JSON.parse(translation?.EnableAppleLoginWebStudent || 'false') &&
                    <Button className='linking_btn'>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: '500', color: '#555555' }}>{translation?.AppleAccount || t('AppleAccount')}</Typography>
                        <img src={apple} alt="apple" />
                      </Box>
                    </Button>
                  }
                </Box>
              </>
              }
              <Box className="scan_box" sx={{cursor: 'pointer'}} onClick={() => setShowQR(true)}>
                <img src={scan} />
                <Typography variant="h4" sx={{ fontSize: '16px', color: '#727272', fontWeight: '500' }}>
                  {translation?.ScanQRCode || t('ScanQRCode')}
                </Typography>
              </Box>
              <Button 
                type="submit"
                className='otp_btn'
                // onClick={sendOtpHandler}
                // disabled={phoneNumber?.length !== country?.NumbersLimit || isApiCalling}
                disabled={isApiCalling}
              >{translation?.SendOTP || t('SendOTP')}</Button>
            </Box> 
          </form>
          }
        </Box>    
      }                
      { tab === 1 &&
        <Box className="right_section">
          <form onSubmit={verifyOtp}>
            <Box className="login_form">
              {error && <Alert className="mb-10" severity="error" onClose={() => setError("")}>{translations[1]?.OtpVerification?.InvalidCode}</Alert>}
              {isResendOTPAlert && <Alert className="mb-10" onClose={() => setIsResendOTPAlert(false)}>{translation.ResendOTP || t('ResendOTP')}</Alert>}
              <Typography variant="h3" sx={{ fontSize: '24px', color: '#0F4D92', fontWeight: '600', marginBottom: '8px', textTransform: 'capitalize' }}>{translation?.OTPVerification || t('OTPVerification')}</Typography>
              <Typography variant="p" sx={{ fontSize: '18px', color: '#555555', fontWeight: '500', marginBottom: '8px' }}>{translation?.SendVerficationCode || t('SendVerficationCode')}  {`${(selectedDialCode + phoneNumber)?.slice(1)}`}</Typography>
              <Typography variant='p' sx={{color: '#0F4D92', fontSize: '18px', display: 'block', fontWeight: '500', cursor: 'pointer' }} onClick={changeNumber}>{translation?.ChangeNumber || t('ChangeNumber')}</Typography>
                <Box className="otp_box">
                
                  <Box className="otp_inputs" sx={{ direction: 'ltr' }}>
                  <OtpInput
                    value={otp}
                    onChange={handleOtpChange}
                    numInputs={6}
                    shouldAutoFocus="true"
                    placeholder={<span>_</span>}
                    inputStyle={inputStyle} />
                </Box>
                <Box className="otp_recieve">
                  <Typography variant='p' sx={{fontSize: '18px', color: '#555555', fontWeight: '500', textTransform: 'capitalize'}}>{translation?.DidNotRecieved || t('DidNotRecieved')}
                  </Typography>
                  { seconds ? 
                    <Typography variant='p' sx={{fontSize: '18px', color: '#0F4D92', fontWeight: '600'}}>
                      <Timers seconds={seconds} setSeconds={setSeconds}/> 
                    </Typography> : 
                    <Typography variant='p' sx={{fontSize: '18px', color: '#0F4D92', fontWeight: '500', textTransform: 'capitalize', cursor: 'pointer'}} onClick={onResendOTPClick} >{translation?.ResendOTP || t('ResendOTP')}</Typography>
                  }
                </Box>
              </Box>
              <Button className='otp_btn' 
                type="submit"
                // onClick={verifyOtp} 
                disabled={otp?.length !== 6 || isApiCalling}>
                {translation?.Login || t('Login')}
              </Button>
            </Box> 
          </form>
        </Box>
      }
      { tab === 2 &&
        <Box className="right_section">
          <Box className="login_form">
            <Typography variant="h3" sx={{ fontSize: '24px', color: '#0F4D92', fontWeight: '600', marginBottom: '8px', textTransform: 'capitalize' }}>{translation?.OTPVerification || t('OTPVerification')}</Typography>
            <Typography variant="p" sx={{ fontSize: '18px', color: '#555555', fontWeight: '500', marginBottom: '8px' }}>{translation.SendVerficationCode || t('SendVerficationCode')} {`${(selectedDialCode + phoneNumber)?.slice(1)}`}</Typography>
            <Typography variant='p' sx={{color: '#0F4D92', fontSize: '18px', display: 'block', fontWeight: '500', cursor: 'pointer' }} onClick={changeNumber}>{translation?.ChangeNumber || t('ChangeNumber')}</Typography>
            <Box className="sms_flex">
              <Button className={`sms_btn ${isWhatsApp ? '' : 'sms_btn_selected'}`} onClick={() => setIsWhatsApp(false)}><img src={msg2} /><Typography sx={{fontSize: '16px', lineHeight: '1', textTransform: 'capitalize'}}>{translation?.SMSMe || t('SMSMe')}</Typography></Button>
              <Button className={`sms_btn ${isWhatsApp ? 'sms_btn_selected' : ''}`} onClick={() => setIsWhatsApp(true)}><img src={whatsapp2} /><Typography sx={{fontSize: '16px', lineHeight: '1', textTransform: 'capitalize'}}>{translation?.WhatsAppMe || t('WhatsAppMe')}</Typography></Button>
            </Box>
            <Button className='otp_btn' 
              type="submit"
              onClick={handleResendOTP}
              >
              {translation?.ResendOTP || t('ResendOTP')}
            </Button>
          </Box>
        </Box>
      }
      <img className="penguine_abs" src={penguine} alt="penguine" />
      <FlagDropDown />
      {isOpenModal && <ErrorModal />}
    </Box>
  )
}

export default Login
