import * as actionTypes from "./actionTypes";
import * as apiCall from "../services/api";
import { API_BASE_URL } from "../config/apiConfig";

const updateStudentProfileSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_STUDENT_PROFILE,
    payload: {
      profile: data.Data,
    },
  };
};

export const updateStudentProfile = (profile) => async (dispatch) => {
  return await apiCall
    .axiosUpdate(`${API_BASE_URL}/UpdateStudentProfileData`, profile)
    .then((response) => {
      if (response) {
        const profileInfo = JSON.parse(sessionStorage.getItem("ProfileInfo"));
        const data = response.data.Data;
        profileInfo.UserName = data.UserName
        profileInfo.StudentGenderID = data.StudentGenderID
        profileInfo.StudentExaminationID = data.StudentExaminationID
        profileInfo.StudentSubExaminationID = data.StudentSubExaminationID
        profileInfo.StudentTargetExamID = data.StudentTargetExamID
        profileInfo.StudentGradeID = data.StudentGradeID
        profileInfo.TeachingLanguage = data.TeachingLanguage
        profileInfo.AppLanguage = data.AppLanguage
        sessionStorage.setItem("ProfileInfo", JSON.stringify(profileInfo))
        dispatch(updateStudentProfileSuccess(response.data));
      }
    })
    .catch((error) => {
      console.log('Error: ', error);
    });
};