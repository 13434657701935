import React, { useState, useEffect, useRef } from 'react'
import { Box, Typography, CircularProgress } from '@mui/material'
import Button from '@mui/material/Button';
import Header from "../header/Header";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import FooterSection from "../footer/FooterSection";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import "../../App.css";
import "../subject/Subject.css";
import "../session-list/SessionList.css";
import "../responsive/Responsive.css";
import teachinfopic from '../../assets/images/techPic1.png';
import hiretutor1 from '../../assets/images/onlineTutor1.png';
import hiretutor2 from '../../assets/images/onlineTutor2.png';
import hiretutor3 from '../../assets/images/onlineTutor3.png';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import useSocket from '../../custom-hooks/useSocket';
import useTranslations from '../../custom-hooks/useTranslations';
import useStudentDashboard from '../../custom-hooks/useStudentDashboard';
import useHelperData from '../../custom-hooks/useHelperData';
import { getTopics } from '../../actions/quickCallsAction';
import { openMessageModal } from '../../actions/modalAction';


import { API_BASE_URL } from '../../config/apiConfig';
import { axiosPost } from '../../services/api';

import MessageModal from '../common-components/MessageModal';
import Advertisement from '../common-components/Advertisement';
import HireTutorModal from '../common-components/HireTutorModal';
import FeedbackModal from '../common-components/FeedbackModal';

// const arr = [
//     hiretutor3,
//     hiretutor2,
//     hiretutor1,
// ]

const HireTutor = () => {
    const navigate = useNavigate();
	const dispatch = useDispatch();
	const { isConnected, socket } = useSocket();
	const translations = useTranslations();
    const searchValueRef = useRef('');
	const [sessionId, setSessionId] = useState();
	const [tutors, setTutors] = useState([]);
    const [verifiedTutors, setVerifiedTutors] = useState([]);
	const [topicIds, setTopicIds] = useState();
	const [topicNames, setTopicNames] = useState();
	const [selectedTopics, setSelectedTopics] = useState("0");
	const [selectedTopicNames, setSelectedTopicNames] = useState();
	const [nationality, setNationality] = useState([]);
	const [selectedNationality, setSelectedNationality] = useState();
	const [price, setPrice] = useState([]);
	const [rating, setRating] = useState([]);
	const [duration, setDuration] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [connectNow, setConnectNow] = useState(false);
    const [feedbackModal, setFeedbackModal] = useState(sessionStorage.getItem('StudentSessionEnd') ? true : false);
    const [sessionFeedBack, setSessionFeedBack] = useState({
        TutorRating: '',
        FeedbackMessage: []
    });

    const [isApiCalling, setIsApiCalling] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

    const [value, setValue] = useState(0);
	const [value1, setValue1] = useState([0, 600]);
	const [value2, setValue2] = useState([0, 5]);
	const [value3, setValue3] = useState([0, 24]);

    const translation = {
        ...translations[0]?.Login,
        ...translations[8]?.PrivateClassDashboard,
        ...translations[7]?.PersonalInfo,
        ...translations[21]?.CommonFrontend,
        ...translations[23]?.TutorRegistration,
        ...translations[26]?.QuickCall,
        ...translations[29]?.FindTutor,
        ...translations[31]?.Wallet,
    }

    const studentRequest = JSON.parse(sessionStorage.getItem('StudentRequest'));
	const privateTutorSessionID = sessionStorage.getItem('SessionID');
	const idToken = JSON.parse(sessionStorage.getItem('loginToken'))?.idToken;
    const profileInfo = JSON.parse(sessionStorage.getItem("ProfileInfo"));

	const studentProfile = useSelector(({ studentProfileReducer }) => studentProfileReducer.student[0] || JSON.parse(sessionStorage.getItem('ProfileInfo')));
	const topics = useSelector(({ topicsReducer }) => topicsReducer.topics);
    const isMessage = useSelector(({ modalReducer }) => modalReducer.isMessage);

    const handleBack = () => {
        navigate(-1);
    }

    const viewTutorProfile = (tutor) => {
        navigate(`/tutor-profile/${btoa(tutor?.ID)}`);
    }

    const onChangeFeedback = (name, value) => {
        setSessionFeedBack((prev) => {
          if (name === 'FeedbackMessage') {
            const updatedFeedBack = prev?.FeedbackMessage?.includes(value)
              ? prev.FeedbackMessage.filter((item) => item !== value)
              : [...prev.FeedbackMessage, value];
      
            return {
              ...prev,
              FeedbackMessage: updatedFeedBack,
            };
          }
      
          return {
            ...prev,
            [name]: value,
          };
        });
    };

    const onSubmitFeedback = async () => {
        sessionStorage.removeItem('StudentSessionEnd');
        setFeedbackModal(false);
        try {
			const data = {
				SessionID: privateTutorSessionID,
				Ratings: sessionFeedBack.TutorRating,
				FeedbackID: sessionFeedBack.FeedbackMessage
			};
			await axiosPost(`${API_BASE_URL}/SavePrivateTutorFeedback`, data);
            setSessionFeedBack({
                TutorRating: '',
                FeedbackMessage: []
            })
            sessionStorage.removeItem("StudentRequest");
            sessionStorage.removeItem("TutorResponse");
            sessionStorage.removeItem("SessionID");
            localStorage.removeItem("CallDuration");
            localStorage.removeItem("CallType");
		} catch (error) {
			console.error('An error occurred while saving feedback:', error);
		}
	}

    const onConnectNowClick = (tutor) => {
        try {
            if (isConnected) {
                
                const payload = {};
                payload['DeviceID'] = sessionStorage.getItem('deviceId');
                payload['EndPoint'] = '/TutorRequest';
                payload['authorization'] = idToken;
                payload['StudentID'] = studentProfile?.ID;
                payload['StudentName'] = studentProfile?.UserName;
                payload['StudentClass'] = studentProfile?.Grade;
                payload['StudentClassID'] = studentProfile?.StudentGradeID.toString();
                payload['StudentImage'] = studentProfile?.ProfilePictureUrl;
                payload['TopicName'] = selectedTopicNames ? selectedTopicNames : topicNames; // send topic name 'English,Maths'
                payload['IsDecline'] = false;
                payload['IsAccept'] = false;
                payload['TutorID'] = parseInt(tutor?.ID);
                payload['langid'] = localStorage.getItem('langId');
                payload['requestType'] = 1; // student = 1 tutor= 2
                payload['TutorName'] = tutor?.UserName;
                payload['TutorImage'] = tutor?.ProfilePictureUrl;
                payload['TutorRating'] = tutor?.Rating;
                payload['Price'] = tutor?.Price;
                payload['TutorPrice'] = tutor?.TutorPrice;
                payload['CurrencyType'] = tutor.CurrencyType;
                payload['TutorTotalSesssion'] = Number(tutor?.SessionCount);
                payload['isPaymentDone'] = 0; // default = 0  success =2  failed =3 // after payment
                payload['SessionID'] = sessionId || 0; // default send 0 // after payment send session id
                sessionStorage.setItem('StudentRequest', JSON.stringify(payload));
                socket.emit('PTModule', JSON.stringify(payload));
                setOpenModal(true);
                setConnectNow(true);
            }
        
        } catch (error) {
            console.log('error');
        }
	}

    const onCancelToConnect = (event, reason) => {	
		const tutorResponse = JSON.parse(sessionStorage.getItem('TutorResponse'));
		if (reason && (reason === "backdropClick"  || reason === "escapeKeyDown")) {
			return;
		}
		const payload = {};
		payload['DeviceID'] = sessionStorage.getItem('deviceId');
		payload['EndPoint'] = '/TutorRequest';
		payload['authorization'] = idToken;
		payload['StudentID'] = studentRequest?.StudentID;
		payload['StudentName'] = studentRequest?.StudentName;
		payload['StudentClass'] = studentRequest?.StudentClass;
		payload['StudentClassID'] = studentRequest?.StudentClassID;
		payload['StudentImage'] = studentRequest?.StudentImage;
		payload['TopicName'] = studentRequest?.TopicName // send topic name 'English,Maths'
		payload['IsDecline'] = true;
		payload['IsAccept'] = false;
		payload['TutorID'] = studentRequest?.TutorID;
		payload['langid'] = localStorage.getItem('langId');
		payload['requestType'] = 1; // student = 1 tutor= 2
		payload['TutorName'] = studentRequest?.TutorName;
		payload['TutorImage'] = studentRequest?.TutorImage;
		payload['TutorRating'] = studentRequest?.TutorRating;
		payload['TutorPrice'] = studentRequest?.TutorPrice;
		payload['TutorTotalSesssion'] = studentRequest?.TutorTotalSesssion;
		payload['isPaymentDone'] = 0; // default = 0  success =2  failed =3 // after payment
		payload['SessionID'] = tutorResponse?.SessionID; // default send 0 // after payment send session id
		socket.emit('PTModule', JSON.stringify(payload));
		sessionStorage.removeItem("StudentRequest");
        sessionStorage.removeItem("TutorResponse");
		sessionStorage.removeItem("SessionID");
		socket.on('GetTutorsResponseEvent', handleTutorResponseEvent);
		setOpenModal(false);
		setConnectNow(false);
	};

    const onCallReject = () => {
		setConnectNow(false);
	};

    const filterBySubjects = (event) => {
        const { value } = event.target;
        setIsLoading(true);
		socket?.off('GetTutorsResponseEvent');
        const topic = topics.find((item) => item.ID.toString() === value)
		setSelectedTopics(value);
		setSelectedTopicNames(topic?.Value);

		const payload = {
			authorization: idToken,
			DeviceID: sessionStorage.getItem('deviceId'),
			langid : localStorage.getItem("langId"),
			EndPoint: '/GetTutors',

			LowestPrice: price[0] || 0,
			HighestPrice: price[1] || 600,
			LowestRating: rating[0] || 0,
			HighestRating: rating[1] || 5,
			LowestDuration: duration[0] || 0,
			HighestDuration: duration[1] || 24,

			Nationalities: selectedNationality || "",
			SearchString: "",
			TopicID: value && value !== 'all' ? [value] : topicIds,
		};

		socket.emit('PTModule', JSON.stringify(payload));
		socket.on('GetTutorsResponseEvent', handleTutorResponseEvent);
	};

    const fetchTutors = () => {
		if (topicIds && !connectNow) {
			const payload = {
				authorization: idToken,
				DeviceID: sessionStorage.getItem('deviceId'),
				langid : localStorage.getItem("langId"),
				EndPoint: '/GetTutors',
				HighestDuration: "",
				HighestPrice: "",
				HighestRating: "",
				LowestDuration: "",
				LowestPrice: "",
				LowestRating: "",
				Nationalities: "",
				SearchString: "",
				TopicID: topicIds || "",
			};
			socket.emit('PTModule', JSON.stringify(payload));
			socket.on('GetTutorsResponseEvent', handleTutorResponseEvent);
		}
	};

    const handleTutorResponseEvent = (response) => {
		const responseData = JSON.parse(response);
		let filterTutors;
		if (responseData?.StatusCode === 200 && responseData.Message === 'Online Tutor Fetched!') {
			// for fetching  tutor
			const lowestPrice = parseInt(responseData?.Data?.LowestPrice) || 0;
			const highestPrice = parseInt(responseData?.Data?.HighestPrice + 1) || 600; // Assuming a default value of 100
			const lowestRating = parseInt(responseData?.Data?.LowestRating) || 0;
			const highestRating = parseInt(responseData?.Data?.HighestRating + 1) || 5;
			const lowestDuration = parseInt(responseData?.Data?.LowestDuration) || 0;
			const highestDuration = parseInt(responseData?.Data?.HighestDuration + 1) || 24;
			setValue1([lowestPrice, highestPrice]);
			setValue2([lowestRating, highestRating]);
			setValue3([lowestDuration, highestDuration]);
			setNationality(responseData?.Data?.Nationalities);
			setSessionId(responseData?.Data?.SessionID)

			if (price?.length === 0) {
				setPrice([lowestPrice, highestPrice]);
			}

			if (rating?.length === 0) {
				setRating([lowestRating, highestRating]);
			}

			if (duration?.length === 0) {
				setDuration([lowestDuration, highestDuration]);
			}
			
			if (responseData?.Data?.SearchResult && searchValueRef.current?.value) {
				filterTutors = (responseData?.Data?.SearchResult || []).filter((item) => {
					const searchValue = searchValueRef.current?.value.toString().toLowerCase();
					const username = item?.UserName?.toString().toLowerCase();
					return username.includes(searchValue);
				});
			} else {
				filterTutors = responseData?.Data?.SearchResult
			}

			setTutors(filterTutors || []);
            setIsApiCalling(false);
            setIsLoading(false);
			// const verifiedTutors = filterTutors?.filter(tutor => tutor.VerificationStatus === "true");
			// setVerifiedTutors(verifiedTutors);
		}
	}

    const extractIds = (topics) => {
		let ids = [];
		topics?.forEach((topic) => {
			ids.push(topic.ID);
		});
		return ids;
	};

	const extractTopicsNames = () => {
		let topicNames = [];
		topics?.forEach((topic) => {
			topicNames.push(topic.Value);
		});
		return topicNames.join(',');
	}

    useEffect(() => {
		if (topics) {
			const topicid = extractIds(topics);
			const topicNames = extractTopicsNames();
			setTopicIds(topicid);
			setTopicNames(topicNames);
		}
	}, [topics]);

    useEffect(() => {
		if (isConnected && topicIds) {
			fetchTutors();
		}
		return () => {
			if (isConnected) {
				let payload = {};
				payload['authorization'] = idToken;
				payload['DeviceID'] = sessionStorage.getItem('deviceId');
				socket?.emit('ClearInterval', JSON.stringify(payload));
				socket.off('GetTutorsResponseEvent');
			} 
		};
	}, [isConnected, topicIds, connectNow]);

    useEffect(() => {
		if (isConnected && connectNow) {
			socket.on('responseEvent', (response) => {
				const responseData = JSON.parse(response);

				if (responseData.StatusCode === 200 && responseData.Message === 'Request send' && responseData.Data[0]) {
					const tutorResponse = responseData.Data[0];
					if (tutorResponse.StudentID.toString() === studentProfile?.ID.toString()) {
						!tutorResponse?.IsDecline && sessionStorage.setItem('TutorResponse', JSON.stringify(tutorResponse));
						if (tutorResponse.requestType.toString() === '2' && tutorResponse.IsAccept === true) {
                            sessionStorage.setItem('pathName', 'hire-tutor');
							setOpenModal(false);
							navigate('/checkout');
						} else {
							if (tutorResponse.requestType.toString() === '2' && tutorResponse.IsAccept === false) {
								setOpenModal(false);
                                dispatch(openMessageModal(translation?.RejectedByTutor))
							}
						}
					}
				} else if (responseData.StatusCode === 402) {
					toast(translation?.LowAmountMessage)
					setOpenModal(false);
					setConnectNow(false);
				}
			});
		}
	}, [isConnected, connectNow]);

	useEffect(() => {
		if (studentProfile) {
			dispatch(getTopics({ GradeID: studentProfile?.StudentGradeID, ExamID: studentProfile?.StudentExaminationID }));
		}
	}, [studentProfile?.StudentGradeID, studentProfile?.StudentExaminationID])

  return (
    <div>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
        />
      <Header/>
       
       <Box className="quikCallBanner">
       <Box className='OuterContainer' sx={{border:'none', borderRadius:'0px'}}>
            <Typography variant='h1'>
                {translation?.Hi} {profileInfo?.UserName}, <Typography variant='div'> {translation?.WelcomeYasa}</Typography>
            </Typography>
        </Box>
       </Box>
        
   

        <Box className='OuterContainer containerShape HireTutorContainer' sx={{background:'#EAF4FF', padding:'30px'}}>
            <Box className='SectionIn'>
                <Box className="BackBtn">
                    <Button className='backBtn' variant="text" sx={{mb:2}}  startIcon={localStorage.getItem("I18N_LANGUAGE") === "en" ? <ArrowBackIcon /> : <ArrowForward />} onClick={handleBack}> {translation?.BackBtn} </Button>
                </Box>
                <Box className="hireTutorMain">
                  <Box className="outerCard" sx={{background:'#fff', borderRadius:'8px', padding:'16px'}}>
                    <Box className="SearchMain">
                       <Box className="fillterDropDown" sx={{gap:2, display:{xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                       <Box className="search-in">
                        <p>{translation?.Keyword}</p>
                        <FormControl fullWidth>
                          <input className='inputBox' placeholder={translation?.SearchHere} ref={searchValueRef}></input>
                          <Typography variant='div' className='serch-icon'>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" stroke="#727272" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M21.0004 21.0004L16.6504 16.6504" stroke="#727272" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                          </Typography>
                        </FormControl>
                        </Box>
                        <Box className="subjectFilter">
                        <p>{translation?.SelectTopic}</p>
                          <FormControl fullWidth>
                              <Select
                                labelId="demo-simple-select-label" 
                                id="demo-simple-select-day" 
                                value={selectedTopics}
                                onChange={filterBySubjects}>
                              {/* <MenuItem value="0" disabled>{translation?.SelectTopics}</MenuItem> */}
                              <MenuItem value="0" disabled>{translation?.SelectTopics} {(isLoading && selectedTopics === '0') && <CircularProgress className="progress sub-progress" />}</MenuItem>
                                {topics?.map((item) => (
                                    <MenuItem key={item?.ID} value={item?.ID}>{item?.Value} {(isLoading && selectedTopics === item?.ID) && <CircularProgress className="progress sub-progress" />}</MenuItem>
                                ))}
                                <MenuItem value="all">{translation?.AllBtn} {(isLoading && selectedTopics === 'all') && <CircularProgress className="progress sub-progress" />}</MenuItem>
                              </Select>
                          </FormControl>
                        </Box>
                      
                        </Box>
                        {/* <Box className="subjectBtns" sx={{mt:1, gap:0.5, display:{xs: 'flex', alignItems: 'center'}}}>
                            <button className="selected">{translation?.Verified}</button>
                            <button>{translation?.TopRated}</button>
                            <button>{translation?.HighestSession}</button>
                            <button>{translation?.ByExperience}</button>
                        </Box> */}
                    </Box>
                  </Box>

                  <Box className="TutorListMain">
                    <Box className="TutorListOnline" sx={{mt:3, mb:2, display:{xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                      <Box className="mainHeading">
                        <h3>{translation?.ListOfTutors}</h3>
                      </Box>
                      <Box className="onlineProfile"  sx={{ display:{xs: 'flex', alignItems: 'center'}}}>
                        <Box className="imageBox" sx={{ display:{xs: 'flex', alignItems: 'center'}}}>
                            { Array.isArray(tutors) && tutors.slice(0, 3).reverse().map((tutor) => {
                                return  <Typography variant='div'>
                                    <img src={tutor?.ProfilePictureUrl}></img>
                                </Typography>
                            })
                            }
                          {/* <Typography variant='div'>
                              <img src={hiretutor1}></img>
                          </Typography>
                          <Typography variant='div'>
                              <img src={hiretutor2}></img>
                          </Typography>
                          <Typography variant='div'>
                              <img src={hiretutor3}></img>
                          </Typography> */}
                        </Box>
                        {/* <h3>{translation?.TutorCount} {translation?.TutorsAreOnline}</h3> */}
                        <h3>{tutors?.length} {translation?.TutorsAreOnline}</h3>
                      </Box>
                    </Box>
                    <Box className="outerCard">
                    { isApiCalling ? 
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </Box> : tutors.length === 0 ?
                        <Box sx={{ display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                            {translation?.NotutorsFound}
                        </Box> : tutors.map((tutor) => {
                            return <Box className="TutorAvailable" key={tutor.ID}>
                            <Box className="tutorAvailableIn tutorStatus" sx={{gap:'15px', display:{xs: 'flex'}}}>
                            <Box 
                                sx={{cursor: 'pointer'}}
                                className={`imageBox ${tutor?.OnlineStatusID === '1' ? 'connectPoint' : tutor?.OnlineStatusID === '2' ? 'busynowPoint' : 'offlinePoint' }`}
                                onClick={() => viewTutorProfile(tutor)}>
                                <img src={tutor?.ProfilePictureUrl}></img>
                            </Box>
                            <Box className="sessionBox">
                                <Box className="contentBox" sx={{mb:1.5}}>
                                    <Box className="content-in"  >
                                <Typography variant='h3' sx={{color:'#005DB8', fontSize:'18px',  display:{xs: 'flex', alignItems:'center'}}}>
                                {tutor?.UserName}
                                <Typography variant='div'>
                                {/* <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_4705_2224)">
                                        <path d="M11.4316 6.23036V6.69036C11.431 7.76857 11.0819 8.8177 10.4363 9.68128C9.79072 10.5449 8.88328 11.1766 7.84932 11.4823C6.81536 11.788 5.71027 11.7513 4.69888 11.3777C3.68748 11.004 2.82397 10.3134 2.23712 9.4089C1.65027 8.50438 1.37154 7.43439 1.44248 6.35852C1.51342 5.28264 1.93024 4.25853 2.63078 3.4389C3.33132 2.61927 4.27803 2.04805 5.32974 1.81043C6.38144 1.57282 7.48177 1.68153 8.46664 2.12036" stroke="#005DB8" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M11.4316 2.69141L6.43164 7.69641L4.93164 6.19641" stroke="#005DB8" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4705_2224">
                                            <rect width="12" height="12" fill="white" transform="translate(0.431641 0.691406)"/>
                                        </clipPath>
                                    </defs>
                                </svg> */}

                                </Typography>
                                </Typography>
                                
                                    <Box className="RatingBox">
                                        <Stack spacing={2}>
                                            <Rating sx={{direction: 'ltr'}} name="half-rating-read" value={parseFloat(tutor?.Rating)} precision={0.5} readOnly />
                                        </Stack>
                                    </Box>
                                    </Box>
                                    <Box className="availableBtn" sx={{gap:'10px', display:{xs: 'flex'}}}>
                                    <button className={`${tutor?.OnlineStatusID === '1' ? 'darkBlue' : tutor?.OnlineStatusID === '2' ? 'busynowBtn' : 'offlinebtn' }`}
                                        disabled={tutor?.OnlineStatusID === '2' || tutor?.OnlineStatusID === '3'}
                                        onClick={() => onConnectNowClick(tutor)}>
                                        {tutor?.OnlineStatusID === '1' ? translation?.ConnectNow : tutor?.OnlineStatusID === '2' ? translation?.BusyStatus : translation?.OfflineStatus }</button>
                                    {/* <button className='bdrbtn' onClick={() => viewTutorProfile(tutor)}>{translation?.TutorProfile}</button> */}
                                </Box>   
                                </Box>
                                <Box className="progressNumber">
                                    <Typography variant='div' className='understood'> <Typography variant='div'>
                                        <svg width="15" height="15" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.76497 18.0241C14.3673 18.0241 18.0983 14.2931 18.0983 9.69075C18.0983 5.08838 14.3673 1.35742 9.76497 1.35742C5.1626 1.35742 1.43164 5.08838 1.43164 9.69075C1.43164 14.2931 5.1626 18.0241 9.76497 18.0241Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M8.09961 6.35742L13.0996 9.69075L8.09961 13.0241V6.35742Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </Typography>  {tutor?.SessionCount} {translation?.Sessions} </Typography>
                                    <Typography variant='div' className='practicing'><Typography variant='div'>
                                        <svg width="16" height="14" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.0977 4.52539H2.76432C1.84385 4.52539 1.09766 5.27158 1.09766 6.19206V14.5254C1.09766 15.4459 1.84385 16.1921 2.76432 16.1921H16.0977C17.0181 16.1921 17.7643 15.4459 17.7643 14.5254V6.19206C17.7643 5.27158 17.0181 4.52539 16.0977 4.52539Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M12.7643 16.1914V2.85807C12.7643 2.41605 12.5887 1.99212 12.2762 1.67956C11.9636 1.367 11.5397 1.19141 11.0977 1.19141H7.76432C7.3223 1.19141 6.89837 1.367 6.58581 1.67956C6.27325 1.99212 6.09766 2.41605 6.09766 2.85807V16.1914" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        </Typography> {tutor?.TeachingExperience}+ {translation?.Years} {translation?.Experience}</Typography>
                                    <Typography variant='div' className='notStarted'><Typography variant='div'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#000000" viewBox="0 0 256 256"><path d="M128,88a40,40,0,1,0,40,40A40,40,0,0,0,128,88Zm0,64a24,24,0,1,1,24-24A24,24,0,0,1,128,152ZM240,56H16a8,8,0,0,0-8,8V192a8,8,0,0,0,8,8H240a8,8,0,0,0,8-8V64A8,8,0,0,0,240,56ZM193.65,184H62.35A56.78,56.78,0,0,0,24,145.65v-35.3A56.78,56.78,0,0,0,62.35,72h131.3A56.78,56.78,0,0,0,232,110.35v35.3A56.78,56.78,0,0,0,193.65,184ZM232,93.37A40.81,40.81,0,0,1,210.63,72H232ZM45.37,72A40.81,40.81,0,0,1,24,93.37V72ZM24,162.63A40.81,40.81,0,0,1,45.37,184H24ZM210.63,184A40.81,40.81,0,0,1,232,162.63V184Z"/></svg>

                                    </Typography> <b className="moneyFont">{tutor?.TutorPrice} {tutor?.CurrencyType} </b> / {translation?.HireTutorHour}</Typography>
                                </Box> 
                            </Box>
                            </Box>
                        </Box>
                      })

                        }
                        </Box>
                    

                    {/* <Box className="outerCard">
                      <Box className="TutorAvailable">
                          <Box className="tutorAvailableIn" sx={{gap:'15px', display:{xs: 'flex'}}}>
                          <Box className="imageBox offlinePoint">
                              <img src={tutorpic}></img>
                          </Box>
                          <Box className="sessionBox">
                              <Box className="contentBox" sx={{mb:1.5}}>
                                  <Box className="content-in"  >
                              <Typography variant='h3' sx={{color:'#005DB8', fontSize:'18px',  display:{xs: 'flex', alignItems:'center'}}}>
                              Ronak Meher
                              <Typography variant='div'>
                              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_4705_2224)">
  <path d="M11.4316 6.23036V6.69036C11.431 7.76857 11.0819 8.8177 10.4363 9.68128C9.79072 10.5449 8.88328 11.1766 7.84932 11.4823C6.81536 11.788 5.71027 11.7513 4.69888 11.3777C3.68748 11.004 2.82397 10.3134 2.23712 9.4089C1.65027 8.50438 1.37154 7.43439 1.44248 6.35852C1.51342 5.28264 1.93024 4.25853 2.63078 3.4389C3.33132 2.61927 4.27803 2.04805 5.32974 1.81043C6.38144 1.57282 7.48177 1.68153 8.46664 2.12036" stroke="#005DB8" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M11.4316 2.69141L6.43164 7.69641L4.93164 6.19641" stroke="#005DB8" strokeLinecap="round" strokeLinejoin="round"/>
  </g>
  <defs>
  <clipPath id="clip0_4705_2224">
  <rect width="12" height="12" fill="white" transform="translate(0.431641 0.691406)"/>
  </clipPath>
  </defs>
  </svg>

                              </Typography>
                              </Typography>
                            
                                  <Box className="RatingBox">
                                      <Stack spacing={2}>
                                          <Rating name="half-rating-read" defaultValue={3} precision={0.5} readOnly />
                                      </Stack>
                                  </Box>
                                  </Box>
                                  <Box className="availableBtn" sx={{gap:'10px', display:{xs: 'flex'}}}>
                                  <button className='offlinebtn'>offline</button>
                                  <button className='bdrbtn'>tutor Profile</button>
                              </Box>   
                              </Box>
                              <Box className="progressNumber">
                                <Typography variant='div' className='understood'> <Typography variant='div'>
                                <svg width="15" height="15" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.76497 18.0241C14.3673 18.0241 18.0983 14.2931 18.0983 9.69075C18.0983 5.08838 14.3673 1.35742 9.76497 1.35742C5.1626 1.35742 1.43164 5.08838 1.43164 9.69075C1.43164 14.2931 5.1626 18.0241 9.76497 18.0241Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M8.09961 6.35742L13.0996 9.69075L8.09961 13.0241V6.35742Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  </Typography>  26 Sessions</Typography>
                                <Typography variant='div' className='practicing'><Typography variant='div'>
                                <svg width="16" height="14" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.0977 4.52539H2.76432C1.84385 4.52539 1.09766 5.27158 1.09766 6.19206V14.5254C1.09766 15.4459 1.84385 16.1921 2.76432 16.1921H16.0977C17.0181 16.1921 17.7643 15.4459 17.7643 14.5254V6.19206C17.7643 5.27158 17.0181 4.52539 16.0977 4.52539Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M12.7643 16.1914V2.85807C12.7643 2.41605 12.5887 1.99212 12.2762 1.67956C11.9636 1.367 11.5397 1.19141 11.0977 1.19141H7.76432C7.3223 1.19141 6.89837 1.367 6.58581 1.67956C6.27325 1.99212 6.09766 2.41605 6.09766 2.85807V16.1914" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

  </Typography> 2+ yr experience</Typography>
                                <Typography variant='div'className='notStarted'><Typography variant='div'>
                                <svg width="10" height="16" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.76562 0.525391V18.8587" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M9.93229 3.85742H3.68229C2.90874 3.85742 2.16688 4.16471 1.6199 4.71169C1.07292 5.25868 0.765625 6.00054 0.765625 6.77409C0.765625 7.54764 1.07292 8.2895 1.6199 8.83648C2.16688 9.38346 2.90874 9.69076 3.68229 9.69076H7.84896C8.62251 9.69076 9.36437 9.99805 9.91135 10.545C10.4583 11.092 10.7656 11.8339 10.7656 12.6074C10.7656 13.381 10.4583 14.1228 9.91135 14.6698C9.36437 15.2168 8.62251 15.5241 7.84896 15.5241H0.765625" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

  </Typography> 10.00 kD / hour</Typography>
                            </Box> 
                          </Box>
                          </Box>
                      </Box>
                    </Box>

                    <Box className="outerCard">
                      <Box className="TutorAvailable">
                          <Box className="tutorAvailableIn" sx={{gap:'15px', display:{xs: 'flex'}}}>
                          <Box className="imageBox busynowPoint">
                              <img src={tutorpic}></img>
                          </Box>
                          <Box className="sessionBox">
                              <Box className="contentBox" sx={{mb:1.5}}>
                                  <Box className="content-in"  >
                              <Typography variant='h3' sx={{color:'#005DB8', fontSize:'18px',  display:{xs: 'flex', alignItems:'center'}}}>
                              Ronak Meher
                              <Typography variant='div'>
                               <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_4705_2224)">
                                <path d="M11.4316 6.23036V6.69036C11.431 7.76857 11.0819 8.8177 10.4363 9.68128C9.79072 10.5449 8.88328 11.1766 7.84932 11.4823C6.81536 11.788 5.71027 11.7513 4.69888 11.3777C3.68748 11.004 2.82397 10.3134 2.23712 9.4089C1.65027 8.50438 1.37154 7.43439 1.44248 6.35852C1.51342 5.28264 1.93024 4.25853 2.63078 3.4389C3.33132 2.61927 4.27803 2.04805 5.32974 1.81043C6.38144 1.57282 7.48177 1.68153 8.46664 2.12036" stroke="#005DB8" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M11.4316 2.69141L6.43164 7.69641L4.93164 6.19641" stroke="#005DB8" strokeLinecap="round" strokeLinejoin="round"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_4705_2224">
                                <rect width="12" height="12" fill="white" transform="translate(0.431641 0.691406)"/>
                                </clipPath>
                                </defs>
                                </svg>
                              </Typography>
                              </Typography>
                            
                                  <Box className="RatingBox">
                                      <Stack spacing={2}>
                                          <Rating name="half-rating-read" defaultValue={3} precision={0.5} readOnly />
                                      </Stack>
                                  </Box>
                                  </Box>
                                  <Box className="availableBtn" sx={{gap:'10px', display:{xs: 'flex'}}}>
                                  <button className='busynowBtn'>busy now</button>
                                  <button className='bdrbtn'>tutor Profile</button>
                              </Box>   
                              </Box>
                              <Box className="progressNumber">
                                <Typography variant='div' className='understood'> <Typography variant='div'>
                                <svg width="15" height="15" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.76497 18.0241C14.3673 18.0241 18.0983 14.2931 18.0983 9.69075C18.0983 5.08838 14.3673 1.35742 9.76497 1.35742C5.1626 1.35742 1.43164 5.08838 1.43164 9.69075C1.43164 14.2931 5.1626 18.0241 9.76497 18.0241Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M8.09961 6.35742L13.0996 9.69075L8.09961 13.0241V6.35742Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  </Typography>  26 Sessions</Typography>
                                <Typography variant='div'className='practicing'><Typography variant='div'>
                                <svg width="16" height="14" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.0977 4.52539H2.76432C1.84385 4.52539 1.09766 5.27158 1.09766 6.19206V14.5254C1.09766 15.4459 1.84385 16.1921 2.76432 16.1921H16.0977C17.0181 16.1921 17.7643 15.4459 17.7643 14.5254V6.19206C17.7643 5.27158 17.0181 4.52539 16.0977 4.52539Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M12.7643 16.1914V2.85807C12.7643 2.41605 12.5887 1.99212 12.2762 1.67956C11.9636 1.367 11.5397 1.19141 11.0977 1.19141H7.76432C7.3223 1.19141 6.89837 1.367 6.58581 1.67956C6.27325 1.99212 6.09766 2.41605 6.09766 2.85807V16.1914" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

  </Typography> 2+ yr experience</Typography>
                                <Typography variant='div'className='notStarted'><Typography variant='div'>
                                <svg width="10" height="16" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.76562 0.525391V18.8587" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M9.93229 3.85742H3.68229C2.90874 3.85742 2.16688 4.16471 1.6199 4.71169C1.07292 5.25868 0.765625 6.00054 0.765625 6.77409C0.765625 7.54764 1.07292 8.2895 1.6199 8.83648C2.16688 9.38346 2.90874 9.69076 3.68229 9.69076H7.84896C8.62251 9.69076 9.36437 9.99805 9.91135 10.545C10.4583 11.092 10.7656 11.8339 10.7656 12.6074C10.7656 13.381 10.4583 14.1228 9.91135 14.6698C9.36437 15.2168 8.62251 15.5241 7.84896 15.5241H0.765625" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

  </Typography> 10.00 kD / hour</Typography>
                            </Box> 
                          </Box>
                          </Box>
                      </Box>
                    </Box>

                    <Box className="outerCard">
                      <Box className="TutorAvailable">
                          <Box className="tutorAvailableIn" sx={{gap:'15px', display:{xs: 'flex'}}}>
                          <Box className="imageBox connectPoint">
                              <img src={tutorpic}></img>
                          </Box>
                          <Box className="sessionBox">
                              <Box className="contentBox" sx={{mb:1.5}}>
                                  <Box className="content-in"  >
                              <Typography variant='h3' sx={{color:'#005DB8', fontSize:'18px',  display:{xs: 'flex', alignItems:'center'}}}>
                              Ronak Meher
                              <Typography variant='div'>
                              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_4705_2224)">
  <path d="M11.4316 6.23036V6.69036C11.431 7.76857 11.0819 8.8177 10.4363 9.68128C9.79072 10.5449 8.88328 11.1766 7.84932 11.4823C6.81536 11.788 5.71027 11.7513 4.69888 11.3777C3.68748 11.004 2.82397 10.3134 2.23712 9.4089C1.65027 8.50438 1.37154 7.43439 1.44248 6.35852C1.51342 5.28264 1.93024 4.25853 2.63078 3.4389C3.33132 2.61927 4.27803 2.04805 5.32974 1.81043C6.38144 1.57282 7.48177 1.68153 8.46664 2.12036" stroke="#005DB8" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M11.4316 2.69141L6.43164 7.69641L4.93164 6.19641" stroke="#005DB8" strokeLinecap="round" strokeLinejoin="round"/>
  </g>
  <defs>
  <clipPath id="clip0_4705_2224">
  <rect width="12" height="12" fill="white" transform="translate(0.431641 0.691406)"/>
  </clipPath>
  </defs>
  </svg>

                              </Typography>
                              </Typography>
                            
                                  <Box className="RatingBox">
                                      <Stack spacing={2}>
                                          <Rating name="half-rating-read" defaultValue={3} precision={0.5} readOnly />
                                      </Stack>
                                  </Box>
                                  </Box>
                                  <Box className="availableBtn" sx={{gap:'10px', display:{xs: 'flex'}}}>
                                  <button className='darkBlue'>connect now</button>
                                  <button className='bdrbtn'>tutor Profile</button>
                              </Box>   
                              </Box>
                              <Box className="progressNumber">
                                <Typography variant='div' className='understood'> <Typography variant='div'>
                                <svg width="15" height="15" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.76497 18.0241C14.3673 18.0241 18.0983 14.2931 18.0983 9.69075C18.0983 5.08838 14.3673 1.35742 9.76497 1.35742C5.1626 1.35742 1.43164 5.08838 1.43164 9.69075C1.43164 14.2931 5.1626 18.0241 9.76497 18.0241Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M8.09961 6.35742L13.0996 9.69075L8.09961 13.0241V6.35742Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  </Typography>  26 Sessions</Typography>
                                <Typography variant='div'className='practicing'><Typography variant='div'>
                                <svg width="16" height="14" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.0977 4.52539H2.76432C1.84385 4.52539 1.09766 5.27158 1.09766 6.19206V14.5254C1.09766 15.4459 1.84385 16.1921 2.76432 16.1921H16.0977C17.0181 16.1921 17.7643 15.4459 17.7643 14.5254V6.19206C17.7643 5.27158 17.0181 4.52539 16.0977 4.52539Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M12.7643 16.1914V2.85807C12.7643 2.41605 12.5887 1.99212 12.2762 1.67956C11.9636 1.367 11.5397 1.19141 11.0977 1.19141H7.76432C7.3223 1.19141 6.89837 1.367 6.58581 1.67956C6.27325 1.99212 6.09766 2.41605 6.09766 2.85807V16.1914" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

  </Typography> 2+ yr experience</Typography>
                                <Typography variant='div'className='notStarted'><Typography variant='div'>
                                <svg width="10" height="16" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.76562 0.525391V18.8587" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M9.93229 3.85742H3.68229C2.90874 3.85742 2.16688 4.16471 1.6199 4.71169C1.07292 5.25868 0.765625 6.00054 0.765625 6.77409C0.765625 7.54764 1.07292 8.2895 1.6199 8.83648C2.16688 9.38346 2.90874 9.69076 3.68229 9.69076H7.84896C8.62251 9.69076 9.36437 9.99805 9.91135 10.545C10.4583 11.092 10.7656 11.8339 10.7656 12.6074C10.7656 13.381 10.4583 14.1228 9.91135 14.6698C9.36437 15.2168 8.62251 15.5241 7.84896 15.5241H0.765625" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

  </Typography> 10.00 kD / hour</Typography>
                            </Box> 
                          </Box>
                          </Box>
                      </Box>
                    </Box>

                    <Box className="outerCard">
                      <Box className="TutorAvailable">
                          <Box className="tutorAvailableIn" sx={{gap:'15px', display:{xs: 'flex'}}}>
                          <Box className="imageBox offlinePoint">
                              <img src={tutorpic}></img>
                          </Box>
                          <Box className="sessionBox">
                              <Box className="contentBox" sx={{mb:1.5}}>
                                  <Box className="content-in"  >
                              <Typography variant='h3' sx={{color:'#005DB8', fontSize:'18px',  display:{xs: 'flex', alignItems:'center'}}}>
                              Ronak Meher
                              <Typography variant='div'>
                              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_4705_2224)">
  <path d="M11.4316 6.23036V6.69036C11.431 7.76857 11.0819 8.8177 10.4363 9.68128C9.79072 10.5449 8.88328 11.1766 7.84932 11.4823C6.81536 11.788 5.71027 11.7513 4.69888 11.3777C3.68748 11.004 2.82397 10.3134 2.23712 9.4089C1.65027 8.50438 1.37154 7.43439 1.44248 6.35852C1.51342 5.28264 1.93024 4.25853 2.63078 3.4389C3.33132 2.61927 4.27803 2.04805 5.32974 1.81043C6.38144 1.57282 7.48177 1.68153 8.46664 2.12036" stroke="#005DB8" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M11.4316 2.69141L6.43164 7.69641L4.93164 6.19641" stroke="#005DB8" strokeLinecap="round" strokeLinejoin="round"/>
  </g>
  <defs>
  <clipPath id="clip0_4705_2224">
  <rect width="12" height="12" fill="white" transform="translate(0.431641 0.691406)"/>
  </clipPath>
  </defs>
  </svg>

                              </Typography>
                              </Typography>
                            
                                  <Box className="RatingBox">
                                      <Stack spacing={2}>
                                          <Rating name="half-rating-read" defaultValue={3} precision={0.5} readOnly />
                                      </Stack>
                                  </Box>
                                  </Box>
                                  <Box className="availableBtn" sx={{gap:'10px', display:{xs: 'flex'}}}>
                                  <button className='offlinebtn'>offline</button>
                                  <button className='bdrbtn'>tutor Profile</button>
                              </Box>   
                              </Box>
                              <Box className="progressNumber">
                                <Typography variant='div' className='understood'> <Typography variant='div'>
                                <svg width="15" height="15" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.76497 18.0241C14.3673 18.0241 18.0983 14.2931 18.0983 9.69075C18.0983 5.08838 14.3673 1.35742 9.76497 1.35742C5.1626 1.35742 1.43164 5.08838 1.43164 9.69075C1.43164 14.2931 5.1626 18.0241 9.76497 18.0241Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M8.09961 6.35742L13.0996 9.69075L8.09961 13.0241V6.35742Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  </Typography>  26 Sessions</Typography>
                                <Typography variant='div'className='practicing'><Typography variant='div'>
                                <svg width="16" height="14" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.0977 4.52539H2.76432C1.84385 4.52539 1.09766 5.27158 1.09766 6.19206V14.5254C1.09766 15.4459 1.84385 16.1921 2.76432 16.1921H16.0977C17.0181 16.1921 17.7643 15.4459 17.7643 14.5254V6.19206C17.7643 5.27158 17.0181 4.52539 16.0977 4.52539Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M12.7643 16.1914V2.85807C12.7643 2.41605 12.5887 1.99212 12.2762 1.67956C11.9636 1.367 11.5397 1.19141 11.0977 1.19141H7.76432C7.3223 1.19141 6.89837 1.367 6.58581 1.67956C6.27325 1.99212 6.09766 2.41605 6.09766 2.85807V16.1914" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

  </Typography> 2+ yr experience</Typography>
                                <Typography variant='div'className='notStarted'><Typography variant='div'>
                                <svg width="10" height="16" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.76562 0.525391V18.8587" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M9.93229 3.85742H3.68229C2.90874 3.85742 2.16688 4.16471 1.6199 4.71169C1.07292 5.25868 0.765625 6.00054 0.765625 6.77409C0.765625 7.54764 1.07292 8.2895 1.6199 8.83648C2.16688 9.38346 2.90874 9.69076 3.68229 9.69076H7.84896C8.62251 9.69076 9.36437 9.99805 9.91135 10.545C10.4583 11.092 10.7656 11.8339 10.7656 12.6074C10.7656 13.381 10.4583 14.1228 9.91135 14.6698C9.36437 15.2168 8.62251 15.5241 7.84896 15.5241H0.765625" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

  </Typography> 10.00 kD / hour</Typography>
                            </Box> 
                          </Box>
                          </Box>
                      </Box>
                    </Box>

                    <Box className="outerCard">
                      <Box className="TutorAvailable">
                          <Box className="tutorAvailableIn" sx={{gap:'15px', display:{xs: 'flex'}}}>
                          <Box className="imageBox busynowPoint">
                              <img src={tutorpic}></img>
                          </Box>
                          <Box className="sessionBox">
                              <Box className="contentBox" sx={{mb:1.5}}>
                                  <Box className="content-in"  >
                              <Typography variant='h3' sx={{color:'#005DB8', fontSize:'18px',  display:{xs: 'flex', alignItems:'center'}}}>
                              Ronak Meher
                              <Typography variant='div'>
                               <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_4705_2224)">
                                <path d="M11.4316 6.23036V6.69036C11.431 7.76857 11.0819 8.8177 10.4363 9.68128C9.79072 10.5449 8.88328 11.1766 7.84932 11.4823C6.81536 11.788 5.71027 11.7513 4.69888 11.3777C3.68748 11.004 2.82397 10.3134 2.23712 9.4089C1.65027 8.50438 1.37154 7.43439 1.44248 6.35852C1.51342 5.28264 1.93024 4.25853 2.63078 3.4389C3.33132 2.61927 4.27803 2.04805 5.32974 1.81043C6.38144 1.57282 7.48177 1.68153 8.46664 2.12036" stroke="#005DB8" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M11.4316 2.69141L6.43164 7.69641L4.93164 6.19641" stroke="#005DB8" strokeLinecap="round" strokeLinejoin="round"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_4705_2224">
                                <rect width="12" height="12" fill="white" transform="translate(0.431641 0.691406)"/>
                                </clipPath>
                                </defs>
                                </svg>
                              </Typography>
                              </Typography>
                            
                                  <Box className="RatingBox">
                                      <Stack spacing={2}>
                                          <Rating name="half-rating-read" defaultValue={3} precision={0.5} readOnly />
                                      </Stack>
                                  </Box>
                                  </Box>
                                  <Box className="availableBtn" sx={{gap:'10px', display:{xs: 'flex'}}}>
                                  <button className='busynowBtn'>busy now</button>
                                  <button className='bdrbtn'>tutor Profile</button>
                              </Box>   
                              </Box>
                              <Box className="progressNumber">
                                <Typography variant='div' className='understood'> <Typography variant='div'>
                                <svg width="15" height="15" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.76497 18.0241C14.3673 18.0241 18.0983 14.2931 18.0983 9.69075C18.0983 5.08838 14.3673 1.35742 9.76497 1.35742C5.1626 1.35742 1.43164 5.08838 1.43164 9.69075C1.43164 14.2931 5.1626 18.0241 9.76497 18.0241Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M8.09961 6.35742L13.0996 9.69075L8.09961 13.0241V6.35742Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  </Typography>  26 Sessions</Typography>
                                <Typography variant='div'className='practicing'><Typography variant='div'>
                                <svg width="16" height="14" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.0977 4.52539H2.76432C1.84385 4.52539 1.09766 5.27158 1.09766 6.19206V14.5254C1.09766 15.4459 1.84385 16.1921 2.76432 16.1921H16.0977C17.0181 16.1921 17.7643 15.4459 17.7643 14.5254V6.19206C17.7643 5.27158 17.0181 4.52539 16.0977 4.52539Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M12.7643 16.1914V2.85807C12.7643 2.41605 12.5887 1.99212 12.2762 1.67956C11.9636 1.367 11.5397 1.19141 11.0977 1.19141H7.76432C7.3223 1.19141 6.89837 1.367 6.58581 1.67956C6.27325 1.99212 6.09766 2.41605 6.09766 2.85807V16.1914" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

  </Typography> 2+ yr experience</Typography>
                                <Typography variant='div'className='notStarted'><Typography variant='div'>
                                <svg width="10" height="16" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.76562 0.525391V18.8587" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M9.93229 3.85742H3.68229C2.90874 3.85742 2.16688 4.16471 1.6199 4.71169C1.07292 5.25868 0.765625 6.00054 0.765625 6.77409C0.765625 7.54764 1.07292 8.2895 1.6199 8.83648C2.16688 9.38346 2.90874 9.69076 3.68229 9.69076H7.84896C8.62251 9.69076 9.36437 9.99805 9.91135 10.545C10.4583 11.092 10.7656 11.8339 10.7656 12.6074C10.7656 13.381 10.4583 14.1228 9.91135 14.6698C9.36437 15.2168 8.62251 15.5241 7.84896 15.5241H0.765625" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

  </Typography> 10.00 kD / hour</Typography>
                            </Box> 
                          </Box>
                          </Box>
                      </Box>
                    </Box>

                    <Box className="outerCard">
                      <Box className="TutorAvailable">
                          <Box className="tutorAvailableIn" sx={{gap:'15px', display:{xs: 'flex'}}}>
                          <Box className="imageBox connectPoint">
                              <img src={tutorpic}></img>
                          </Box>
                          <Box className="sessionBox">
                              <Box className="contentBox" sx={{mb:1.5}}>
                                  <Box className="content-in"  >
                              <Typography variant='h3' sx={{color:'#005DB8', fontSize:'18px',  display:{xs: 'flex', alignItems:'center'}}}>
                              Ronak Meher
                              <Typography variant='div'>
                              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_4705_2224)">
  <path d="M11.4316 6.23036V6.69036C11.431 7.76857 11.0819 8.8177 10.4363 9.68128C9.79072 10.5449 8.88328 11.1766 7.84932 11.4823C6.81536 11.788 5.71027 11.7513 4.69888 11.3777C3.68748 11.004 2.82397 10.3134 2.23712 9.4089C1.65027 8.50438 1.37154 7.43439 1.44248 6.35852C1.51342 5.28264 1.93024 4.25853 2.63078 3.4389C3.33132 2.61927 4.27803 2.04805 5.32974 1.81043C6.38144 1.57282 7.48177 1.68153 8.46664 2.12036" stroke="#005DB8" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M11.4316 2.69141L6.43164 7.69641L4.93164 6.19641" stroke="#005DB8" strokeLinecap="round" strokeLinejoin="round"/>
  </g>
  <defs>
  <clipPath id="clip0_4705_2224">
  <rect width="12" height="12" fill="white" transform="translate(0.431641 0.691406)"/>
  </clipPath>
  </defs>
  </svg>

                              </Typography>
                              </Typography>
                            
                                  <Box className="RatingBox">
                                      <Stack spacing={2}>
                                          <Rating name="half-rating-read" defaultValue={3} precision={0.5} readOnly />
                                      </Stack>
                                  </Box>
                                  </Box>
                                  <Box className="availableBtn" sx={{gap:'10px', display:{xs: 'flex'}}}>
                                  <button className='darkBlue'>connect now</button>
                                  <button className='bdrbtn'>tutor Profile</button>
                              </Box>   
                              </Box>
                              <Box className="progressNumber">
                                <Typography variant='div' className='understood'> <Typography variant='div'>
                                <svg width="15" height="15" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.76497 18.0241C14.3673 18.0241 18.0983 14.2931 18.0983 9.69075C18.0983 5.08838 14.3673 1.35742 9.76497 1.35742C5.1626 1.35742 1.43164 5.08838 1.43164 9.69075C1.43164 14.2931 5.1626 18.0241 9.76497 18.0241Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M8.09961 6.35742L13.0996 9.69075L8.09961 13.0241V6.35742Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  </Typography>  26 Sessions</Typography>
                                <Typography variant='div'className='practicing'><Typography variant='div'>
                                <svg width="16" height="14" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.0977 4.52539H2.76432C1.84385 4.52539 1.09766 5.27158 1.09766 6.19206V14.5254C1.09766 15.4459 1.84385 16.1921 2.76432 16.1921H16.0977C17.0181 16.1921 17.7643 15.4459 17.7643 14.5254V6.19206C17.7643 5.27158 17.0181 4.52539 16.0977 4.52539Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M12.7643 16.1914V2.85807C12.7643 2.41605 12.5887 1.99212 12.2762 1.67956C11.9636 1.367 11.5397 1.19141 11.0977 1.19141H7.76432C7.3223 1.19141 6.89837 1.367 6.58581 1.67956C6.27325 1.99212 6.09766 2.41605 6.09766 2.85807V16.1914" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

  </Typography> 2+ yr experience</Typography>
                                <Typography variant='div'className='notStarted'><Typography variant='div'>
                                <svg width="10" height="16" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.76562 0.525391V18.8587" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M9.93229 3.85742H3.68229C2.90874 3.85742 2.16688 4.16471 1.6199 4.71169C1.07292 5.25868 0.765625 6.00054 0.765625 6.77409C0.765625 7.54764 1.07292 8.2895 1.6199 8.83648C2.16688 9.38346 2.90874 9.69076 3.68229 9.69076H7.84896C8.62251 9.69076 9.36437 9.99805 9.91135 10.545C10.4583 11.092 10.7656 11.8339 10.7656 12.6074C10.7656 13.381 10.4583 14.1228 9.91135 14.6698C9.36437 15.2168 8.62251 15.5241 7.84896 15.5241H0.765625" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

  </Typography> 10.00 kD / hour</Typography>
                            </Box> 
                          </Box>
                          </Box>
                      </Box>
                    </Box>

                    <Box className="outerCard">
                      <Box className="TutorAvailable">
                          <Box className="tutorAvailableIn" sx={{gap:'15px', display:{xs: 'flex'}}}>
                          <Box className="imageBox offlinePoint">
                              <img src={tutorpic}></img>
                          </Box>
                          <Box className="sessionBox">
                              <Box className="contentBox" sx={{mb:1.5}}>
                                  <Box className="content-in"  >
                              <Typography variant='h3' sx={{color:'#005DB8', fontSize:'18px',  display:{xs: 'flex', alignItems:'center'}}}>
                              Ronak Meher
                              <Typography variant='div'>
                              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_4705_2224)">
  <path d="M11.4316 6.23036V6.69036C11.431 7.76857 11.0819 8.8177 10.4363 9.68128C9.79072 10.5449 8.88328 11.1766 7.84932 11.4823C6.81536 11.788 5.71027 11.7513 4.69888 11.3777C3.68748 11.004 2.82397 10.3134 2.23712 9.4089C1.65027 8.50438 1.37154 7.43439 1.44248 6.35852C1.51342 5.28264 1.93024 4.25853 2.63078 3.4389C3.33132 2.61927 4.27803 2.04805 5.32974 1.81043C6.38144 1.57282 7.48177 1.68153 8.46664 2.12036" stroke="#005DB8" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M11.4316 2.69141L6.43164 7.69641L4.93164 6.19641" stroke="#005DB8" strokeLinecap="round" strokeLinejoin="round"/>
  </g>
  <defs>
  <clipPath id="clip0_4705_2224">
  <rect width="12" height="12" fill="white" transform="translate(0.431641 0.691406)"/>
  </clipPath>
  </defs>
  </svg>

                              </Typography>
                              </Typography>
                            
                                  <Box className="RatingBox">
                                      <Stack spacing={2}>
                                          <Rating name="half-rating-read" defaultValue={3} precision={0.5} readOnly />
                                      </Stack>
                                  </Box>
                                  </Box>
                                  <Box className="availableBtn" sx={{gap:'10px', display:{xs: 'flex'}}}>
                                  <button className='offlinebtn'>offline</button>
                                  <button className='bdrbtn'>tutor Profile</button>
                              </Box>   
                              </Box>
                              <Box className="progressNumber">
                                <Typography variant='div' className='understood'> <Typography variant='div'>
                                <svg width="15" height="15" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.76497 18.0241C14.3673 18.0241 18.0983 14.2931 18.0983 9.69075C18.0983 5.08838 14.3673 1.35742 9.76497 1.35742C5.1626 1.35742 1.43164 5.08838 1.43164 9.69075C1.43164 14.2931 5.1626 18.0241 9.76497 18.0241Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M8.09961 6.35742L13.0996 9.69075L8.09961 13.0241V6.35742Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  </Typography>  26 Sessions</Typography>
                                <Typography variant='div'className='practicing'><Typography variant='div'>
                                <svg width="16" height="14" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.0977 4.52539H2.76432C1.84385 4.52539 1.09766 5.27158 1.09766 6.19206V14.5254C1.09766 15.4459 1.84385 16.1921 2.76432 16.1921H16.0977C17.0181 16.1921 17.7643 15.4459 17.7643 14.5254V6.19206C17.7643 5.27158 17.0181 4.52539 16.0977 4.52539Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M12.7643 16.1914V2.85807C12.7643 2.41605 12.5887 1.99212 12.2762 1.67956C11.9636 1.367 11.5397 1.19141 11.0977 1.19141H7.76432C7.3223 1.19141 6.89837 1.367 6.58581 1.67956C6.27325 1.99212 6.09766 2.41605 6.09766 2.85807V16.1914" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

  </Typography> 2+ yr experience</Typography>
                                <Typography variant='div'className='notStarted'><Typography variant='div'>
                                <svg width="10" height="16" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.76562 0.525391V18.8587" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M9.93229 3.85742H3.68229C2.90874 3.85742 2.16688 4.16471 1.6199 4.71169C1.07292 5.25868 0.765625 6.00054 0.765625 6.77409C0.765625 7.54764 1.07292 8.2895 1.6199 8.83648C2.16688 9.38346 2.90874 9.69076 3.68229 9.69076H7.84896C8.62251 9.69076 9.36437 9.99805 9.91135 10.545C10.4583 11.092 10.7656 11.8339 10.7656 12.6074C10.7656 13.381 10.4583 14.1228 9.91135 14.6698C9.36437 15.2168 8.62251 15.5241 7.84896 15.5241H0.765625" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

  </Typography> 10.00 kD / hour</Typography>
                            </Box> 
                          </Box>
                          </Box>
                      </Box>
                    </Box>

                    <Box className="outerCard">
                      <Box className="TutorAvailable">
                          <Box className="tutorAvailableIn" sx={{gap:'15px', display:{xs: 'flex'}}}>
                          <Box className="imageBox busynowPoint">
                              <img src={tutorpic}></img>
                          </Box>
                          <Box className="sessionBox">
                              <Box className="contentBox" sx={{mb:1.5}}>
                                  <Box className="content-in"  >
                              <Typography variant='h3' sx={{color:'#005DB8', fontSize:'18px',  display:{xs: 'flex', alignItems:'center'}}}>
                              Ronak Meher
                              <Typography variant='div'>
                               <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_4705_2224)">
                                <path d="M11.4316 6.23036V6.69036C11.431 7.76857 11.0819 8.8177 10.4363 9.68128C9.79072 10.5449 8.88328 11.1766 7.84932 11.4823C6.81536 11.788 5.71027 11.7513 4.69888 11.3777C3.68748 11.004 2.82397 10.3134 2.23712 9.4089C1.65027 8.50438 1.37154 7.43439 1.44248 6.35852C1.51342 5.28264 1.93024 4.25853 2.63078 3.4389C3.33132 2.61927 4.27803 2.04805 5.32974 1.81043C6.38144 1.57282 7.48177 1.68153 8.46664 2.12036" stroke="#005DB8" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M11.4316 2.69141L6.43164 7.69641L4.93164 6.19641" stroke="#005DB8" strokeLinecap="round" strokeLinejoin="round"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_4705_2224">
                                <rect width="12" height="12" fill="white" transform="translate(0.431641 0.691406)"/>
                                </clipPath>
                                </defs>
                                </svg>
                              </Typography>
                              </Typography>
                            
                                  <Box className="RatingBox">
                                      <Stack spacing={2}>
                                          <Rating name="half-rating-read" defaultValue={3} precision={0.5} readOnly />
                                      </Stack>
                                  </Box>
                                  </Box>
                                  <Box className="availableBtn" sx={{gap:'10px', display:{xs: 'flex'}}}>
                                  <button className='busynowBtn'>busy now</button>
                                  <button className='bdrbtn'>tutor Profile</button>
                              </Box>   
                              </Box>
                              <Box className="progressNumber">
                                <Typography variant='div' className='understood'> <Typography variant='div'>
                                <svg width="15" height="15" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.76497 18.0241C14.3673 18.0241 18.0983 14.2931 18.0983 9.69075C18.0983 5.08838 14.3673 1.35742 9.76497 1.35742C5.1626 1.35742 1.43164 5.08838 1.43164 9.69075C1.43164 14.2931 5.1626 18.0241 9.76497 18.0241Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M8.09961 6.35742L13.0996 9.69075L8.09961 13.0241V6.35742Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  </Typography>  26 Sessions</Typography>
                                <Typography variant='div'className='practicing'><Typography variant='div'>
                                <svg width="16" height="14" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.0977 4.52539H2.76432C1.84385 4.52539 1.09766 5.27158 1.09766 6.19206V14.5254C1.09766 15.4459 1.84385 16.1921 2.76432 16.1921H16.0977C17.0181 16.1921 17.7643 15.4459 17.7643 14.5254V6.19206C17.7643 5.27158 17.0181 4.52539 16.0977 4.52539Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M12.7643 16.1914V2.85807C12.7643 2.41605 12.5887 1.99212 12.2762 1.67956C11.9636 1.367 11.5397 1.19141 11.0977 1.19141H7.76432C7.3223 1.19141 6.89837 1.367 6.58581 1.67956C6.27325 1.99212 6.09766 2.41605 6.09766 2.85807V16.1914" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

  </Typography> 2+ yr experience</Typography>
                                <Typography variant='div'className='notStarted'><Typography variant='div'>
                                <svg width="10" height="16" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.76562 0.525391V18.8587" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M9.93229 3.85742H3.68229C2.90874 3.85742 2.16688 4.16471 1.6199 4.71169C1.07292 5.25868 0.765625 6.00054 0.765625 6.77409C0.765625 7.54764 1.07292 8.2895 1.6199 8.83648C2.16688 9.38346 2.90874 9.69076 3.68229 9.69076H7.84896C8.62251 9.69076 9.36437 9.99805 9.91135 10.545C10.4583 11.092 10.7656 11.8339 10.7656 12.6074C10.7656 13.381 10.4583 14.1228 9.91135 14.6698C9.36437 15.2168 8.62251 15.5241 7.84896 15.5241H0.765625" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

  </Typography> 10.00 kD / hour</Typography>
                            </Box> 
                          </Box>
                          </Box>
                      </Box>
                    </Box> */}

                    
                  </Box>

                  {/* <Box className="viewMore" sx={{mt:3, mb:3, gap:0.5, display:{xs: 'flex', alignItems: 'center', justifyContent:'center'}}}>
                      <button className='viewMoreBtn'>view more (10)</button>
                  </Box> */}

                  <Advertisement />
                  {/* <Box className="AdverBanner" sx={{display:{xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                    <Box className="contentBox">
                        <Box className="ImgBox">
                        <img src={advertising1}></img>
                        </Box>
                        <Typography variant='h2' sx={{mt:2, mb:2}}>
                        you can control your learning session
                        </Typography>
                        <Box className="AppStore" sx={{ gap:1, display:{xs: 'flex', alignItems: 'center'}}}>
                            <Typography variant='div'>
                            <img src={advertising2}></img>
                            </Typography>
                            <Typography variant='div'>
                            <img src={advertising3}></img>
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="imageBox">
                    <img src={advertising4}></img>
                    </Box>
                </Box> */}

                </Box>
            </Box>
        </Box>
        <HireTutorModal isOpen={openModal} studentRequest={studentRequest} translation={translation} handleClose={onCancelToConnect} />
        <MessageModal isOpen={isMessage} btnTxt={translation?.OkButton} handleClose={onCallReject} />       
        <FeedbackModal isOpen={feedbackModal} feedback={sessionFeedBack} translation={translation} onChangeFeedback={onChangeFeedback} onSubmitFeedback={onSubmitFeedback} />
        <FooterSection/>
    </div>
  )
}

export default HireTutor