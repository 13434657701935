import axios from "axios";
import { API_BASE_URL } from "../config/apiConfig";

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(({ resolve, reject }) => {
    if (error) {
      reject(error);
    } else {
      resolve(token);
    }
  });
  failedQueue = [];
};

const setHeaders = (config) => {
  const loginToken = JSON.parse(sessionStorage.getItem("loginToken"));
  config.headers["authorization"] = loginToken?.idToken;
  config.headers["AuthToken"] = localStorage.getItem("customToken");
  config.headers["deviceid"] = sessionStorage.getItem("deviceId");
  config.headers["langid"] = localStorage.getItem("langId") || 2;
  config.headers["CountryID"] = localStorage.getItem("CountryID") || 113;
  config.headers["RoleID"] = "3";
  config.headers["Environment"] = config.url?.includes("GetLocalizationsString")
    ? "Prod"
    : localStorage.getItem("Environment") || "Prod";

  return config;
};

axios.interceptors.request.use((config) => setHeaders(config));

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { response, config: originalRequest } = error;

    if (response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["authorization"] = token;
            return axios(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const loginToken = JSON.parse(sessionStorage.getItem("loginToken") || "{}");
        if (loginToken?.idToken) {
          const { data } = await axios.get(`${API_BASE_URL}/CustomToken`);
          const payload = {
            idToken: data?.Token,
            expiresIn: loginToken?.expiresIn,
            localId: loginToken?.localId,
            expireTime: Date.now(),
          };
          sessionStorage.setItem("loginToken", JSON.stringify(payload));

          axios.defaults.headers.common["authorization"] = data?.Token;
          processQueue(null, data?.Token);

          // Retry the original request with the new token
          return axios(originalRequest);
        } else {
          throw new Error("Login token not found");
        }
      } catch (err) {
        processQueue(err, null);
        sessionStorage.clear();
        window.location.href = "/login";
        return Promise.reject(err); // Navigate to login if token refresh fails
      } finally {
        isRefreshing = false;
      }
    }

    if (response?.status === 401) {
      sessionStorage.clear();
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export const axiosGet = (url) => axios.get(url);
export const axiosPost = (url, data) => axios.post(url, data);
export const axiosUpdate = (url, data) => axios.patch(url, data);
