import React, { useState, useEffect } from 'react'
import { Box, Typography, IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

import Header from "../header/Header";
import FooterSection from "../footer/FooterSection";
import SessionReportModal from '../common-components/SessionReportModal';

import "../../App.css";
import "../subject/Subject.css";
import "../session-list/SessionList.css";
import "../overview/Overview.css";
import "../responsive/Responsive.css";

import { useNavigate, useParams } from 'react-router-dom';

import Advertisement from '../common-components/Advertisement';
import { ZoomImageContainer, openImage } from '../common-components/ZoomImageContainer';


import useTranslations from '../../custom-hooks/useTranslations';
import useStudentDashboard from '../../custom-hooks/useStudentDashboard';
import useTutorInformation from '../../custom-hooks/useTutorInformation';

import momentDate from '../../services/momentDate';
import timeAgoDate from '../../services/timeAgoDate';

import { API_BASE_URL } from '../../config/apiConfig';
import { axiosPost } from '../../services/api';

const SessionDetail = () => {
    const navigate = useNavigate();
    let { sessionId, tutorId } = useParams();
    const translations = useTranslations();
    const { getRecentSession, bookmarkSession } = useStudentDashboard();
    const { getTutorSession, bookmarkTutorSession } = useTutorInformation(atob(tutorId));
    sessionId = atob(sessionId)
    const session = sessionId?.includes('RS') ? getRecentSession(sessionId.slice(2)) : getTutorSession(sessionId.slice(2))
    console.log('session: ', session);
    const [tab, setTab] = useState(0);
    const [isReport, setIsReport] = useState(false);
    const [reportId, setReportId] = useState(null);
    const [chatEvents, setChatEvents] = useState(null);
    // const [isPlaying, setIsPlaying] = useState(false);

    const profileInfo = JSON.parse(sessionStorage.getItem("ProfileInfo"));

    const translation = {
        ...translations[15]?.SessionVideo,
        ...translations[21]?.CommonFrontend,
        ...translations[26]?.QuickCall
    }

    function TabPanel(props) {
		const { children, value, index, ...other } = props;

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && (
					<Box sx={{ p: 3 }}>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	}

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

    const handleScheduleClass = () => {
        window.open('https://www.getyasa.com/book-private-class', '_blank')
    }

    const handleChange = (event, newValue) => {
		setTab(newValue);
	};

    const handleBack = () => {
        navigate(-1);
    }

    const viewTutorProfile = (tutorId) => {
		navigate(`/tutor-profile/${btoa(tutorId)}`);
	}

    const handleBookMark = async () => {
        if (sessionId?.includes('RS')) {
            bookmarkSession(session?.ID);
        } else {
            bookmarkTutorSession(session?.ID);
        }
        const response = await axiosPost(`${API_BASE_URL}/BookMarkSession`, {
            "SessionID": session?.ID,
            "SessionType": session?.Type
        })
    }

    const handleReport = () => {
        setIsReport(true);
    }

    const onChangeReport = (e) => {
        setReportId(e.target.value);
    }

    const closeReportModal = () => {
        setIsReport(false);
    }

    const onSubmitReport = async () => {
        setIsReport(false);
        await axiosPost(`${API_BASE_URL}/ReportQuickCall`, {
            SessionID: session.ID,
            ReportID: reportId
        })
        setReportId(null);
    }

    const fetchChatBackup = () => {
        // if Quick Call = 0 then pass "S with sessionId"
        // if Hire Tutor = 4 then pass "PT with sessionId"
        const classID = session?.Type === "PT" ? session?.Type + session?.ID : session?.Type === "QC" ? "S" + session?.ID : "";

        const data = {
            ClassID: classID
        };

        axiosPost(`${API_BASE_URL}/GetChatdata`, data).then((res) => {
            setChatEvents(res.data.Data);
        });
    };

    // const handlePlayClick = (e) => {
    //     setIsPlaying(true);
    // };

    useEffect(() => {
        if (session) {
            fetchChatBackup();
        }
    }, [session])

  return (
  
    <div>
        <Header/>
        <Box className="quikCallBanner">
            <Box className='OuterContainer' sx={{border:'none', borderRadius:'0px'}}>
                <Typography variant='h1'>
                    {translation?.Hi} {profileInfo?.UserName}, <Typography variant='div'> {translation?.WelcomeYasa}</Typography>
                </Typography>
            </Box>
       </Box>

       <Box className='OuterContainer containerShape' sx={{background:'#EAF4FF', padding:'30px'}}>
            <Box className='SectionIn'>
                <Box className="BackBtn">
                    <Button className='backBtn' variant="text" sx={{mb:2}} startIcon={localStorage.getItem("I18N_LANGUAGE") === "en" ? <ArrowBackIcon /> : <ArrowForward />} onClick={handleBack}> {translation?.BackBtn} </Button>
                </Box>
                <Box className="tutorMain">
                    <Box className="RecentSessionDetails" sx={{mt:3}}>
                            <Box className="outerCard">
                                <Box className="VideoBox">
                                    { session?.RecordedSessionURL ? 
                                        <video controls autoPlay 
                                            onLoadedMetadata={(e) => {
                                                e.target.currentTime = 15; // Skip the first 15 seconds
                                            }}
                                            >
                                            <source 
                                            src={session?.RecordedSessionURL} 
                                            type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video> : <Box className="VideoMsg">
                                            {translation?.VideoWillBeAvailable}
                                        </Box>
                                    }
                                {/* { isPlaying ? 
                                    <video controls autoPlay>
                                        <source src={session?.RecordedSessionURL} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video> : 
                                    <VideoThumbnail
                                        videoUrl={session?.RecordedSessionURL}
                                        snapshotAtTime ={20}
                                    />
                                } */}
                                {/* <img src={tutorpic2}></img> */}
                                {/* { !isPlaying && <>
                                    <Box className="playBtn" onClick={handlePlayClick}>
                                        <svg width="18" height="18" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.3861 5.24298C12.7788 6.00033 12.7788 7.99967 11.3861 8.75702L3.2393 13.1871C1.90661 13.9118 0.283854 12.9471 0.283854 11.4301L0.283854 2.56991C0.283854 1.05293 1.90661 0.0881979 3.23929 0.81289L11.3861 5.24298Z" fill="white"/>
                                        </svg>
                                    </Box>

                               
                                    <Box className="videoInBts" sx={{ display:{xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                                        <button className='hireBtn'>{session?.Type === 'PT' ? 'Hire Tutor' : session?.Type === 'QC' ? 'Quick Call' : 'Online'}</button>
                                        <button className='timerBtn'>{session?.CallDuration}</button>
                                    </Box>
                                </>} */}
                                </Box>
                                <Box className="contentBox">
                                    { session?.Notes && 
                                        <Typography variant='h5' sx={{mb:2, mb:0.5}}>
                                            {session?.Notes}
                                        </Typography> 
                                    }
                                    <Box className="TimeRankBox" sx={{ display:{xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                                        <Box className="InnerBox">
                                            <Box className="dateTimeBox" sx={{gap:1, display:{xs: 'flex', alignItems: 'center'}}}>
                                                <Typography variant='div'>{timeAgoDate(session?.StartDateTime || session?.CreatedDate)}</Typography>|
                                                <Typography variant='div'>{momentDate(session?.StartDateTime || session?.CreatedDate)}</Typography>
                                            </Box>
                                            <Box className="mathsBtn">
                                                <button className='sky radius4'>{session?.TopicName}</button>
                                            </Box>
                                        </Box>
                                        
                                        <Box className="RatingBox">
                                            <Stack spacing={2} sx={{mb:0.5, display:{xs: 'flex', alignItems: 'flex-end'}}}>
                                                <Rating sx={{direction: 'ltr'}} name="half-rating-read" value={parseFloat(session?.Ratings)} precision={0.5} readOnly />
                                            </Stack>

                                            <Box className="BookMarkReport" sx={{gap:'15px', display:{xs: 'flex', alignItems: 'flex-end'}}}>
                                                <button onClick={handleBookMark}>
                                                    {session?.isBookmarked ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                                                    {translation?.Bookmark}
                                                </button>
                                                {/* <button onClick={handleBookMark}><svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 16.25V2.366C0 1.90533 0.154333 1.521 0.463 1.213C0.771667 0.905 1.156 0.750667 1.616 0.75H10.385C10.845 0.75 11.2293 0.904333 11.538 1.213C11.8467 1.52167 12.0007 1.906 12 2.366V16.25L6 13.673L0 16.25ZM1 14.7L6 12.55L11 14.7V2.366C11 2.212 10.936 2.07067 10.808 1.942C10.68 1.81333 10.5387 1.74933 10.384 1.75H1.616C1.462 1.75 1.32067 1.814 1.192 1.942C1.06333 2.07 0.999333 2.21133 1 2.366V14.7Z" fill="#555555"/>
                                                </svg>
                                                    {translation?.Bookmark} 
                                                </button> */}
                                                <button onClick={handleReport}>
                                                    {/* <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8 12.923C8.17467 12.923 8.321 12.864 8.439 12.746C8.55633 12.628 8.615 12.482 8.615 12.308C8.615 12.134 8.556 11.9877 8.438 11.869C8.32 11.7503 8.174 11.6913 8 11.692C7.826 11.6927 7.68 11.7517 7.562 11.869C7.444 11.9863 7.385 12.1327 7.385 12.308C7.385 12.4833 7.444 12.6293 7.562 12.746C7.68 12.8627 7.826 12.9217 8 12.923ZM7.5 9.962H8.5V3.884H7.5V9.962ZM4.673 16.5L0 11.836V5.173L4.664 0.5H11.327L16 5.164V11.827L11.336 16.5H4.673ZM5.1 15.5H10.9L15 11.4V5.6L10.9 1.5H5.1L1 5.6V11.4L5.1 15.5Z" fill="#555555"/>
                                                    </svg> */}
                                                    <ReportGmailerrorredIcon />
                                                    {translation?.Report}
                                                </button>
                                            </Box>
                                        </Box>
                                    </Box>                                     
                                </Box>

                                <Box className="sessionCard radius8" sx={{mt:2}}>
                                    <Typography variant='h5' sx={{mb:1}}><b>{translation?.SessionBy}</b></Typography>
                                    <Box className="innerBox" sx={{ display:{xs: 'flex', alignItems: 'top', justifyContent:'space-between'}}}>
                                        <Box className="RankInfo" sx={{gap:1.5, display:{xs: 'flex', alignItems: 'top'}}}>
                                            <Box 
                                            className={`ProfilePic ${session?.OnlineStatusID === '1' ? 'add-online' : session?.OnlineStatusID === '2' ? 'add-busy' : 'add-offline' }`}  
                                            onClick={() => viewTutorProfile(session?.TutorID)}>
                                                <img src={session?.TutorProfilePicture}></img>
                                            </Box>
                                            <Box className="TeachInfo">
                                                <Typography variant='h5'>{session?.TutorName}</Typography>
                                                <Box className="RatingBox">
                                                    <Stack spacing={2}>
                                                        <Rating sx={{direction: 'ltr'}} name="half-rating-read" value={parseFloat(session?.TutorRating)} precision={0.5} readOnly />
                                                    </Stack>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box className="availableBtn" sx={{gap:'15px', display:{xs: 'flex'}}}>
                                        {/* <button className='darkYellow'>{translation?.ConnectNow}</button> */}
                                        <button className='darkBlue' onClick={handleScheduleClass}>
                                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.75 2.5H8.25V1.75C8.25 1.6837 8.22366 1.62011 8.17678 1.57322C8.12989 1.52634 8.0663 1.5 8 1.5C7.9337 1.5 7.87011 1.52634 7.82322 1.57322C7.77634 1.62011 7.75 1.6837 7.75 1.75V2.5H4.25V1.75C4.25 1.6837 4.22366 1.62011 4.17678 1.57322C4.12989 1.52634 4.0663 1.5 4 1.5C3.9337 1.5 3.87011 1.52634 3.82322 1.57322C3.77634 1.62011 3.75 1.6837 3.75 1.75V2.5H2.25C1.9186 2.5004 1.60089 2.63222 1.36655 2.86655C1.13222 3.10089 1.0004 3.4186 1 3.75V10.25C1.0004 10.5814 1.13222 10.8991 1.36655 11.1334C1.60089 11.3678 1.9186 11.4996 2.25 11.5H9.75C10.0814 11.4997 10.3992 11.368 10.6336 11.1336C10.868 10.8992 10.9997 10.5814 11 10.25V3.75C10.9997 3.41856 10.868 3.10077 10.6336 2.86641C10.3992 2.63205 10.0814 2.50026 9.75 2.5ZM10.5 10.25C10.5 10.4489 10.421 10.6397 10.2803 10.7803C10.1397 10.921 9.94891 11 9.75 11H2.25C2.05109 11 1.86032 10.921 1.71967 10.7803C1.57902 10.6397 1.5 10.4489 1.5 10.25V6H10.5V10.25ZM10.5 5.5H1.5V3.75C1.5 3.336 1.835 3 2.25 3H3.75V3.75C3.75 3.8163 3.77634 3.87989 3.82322 3.92678C3.87011 3.97366 3.9337 4 4 4C4.0663 4 4.12989 3.97366 4.17678 3.92678C4.22366 3.87989 4.25 3.8163 4.25 3.75V3H7.75V3.75C7.75 3.8163 7.77634 3.87989 7.82322 3.92678C7.87011 3.97366 7.9337 4 8 4C8.0663 4 8.12989 3.97366 8.17678 3.92678C8.22366 3.87989 8.25 3.8163 8.25 3.75V3H9.75C9.94891 3 10.1397 3.07902 10.2803 3.21967C10.421 3.36032 10.5 3.55109 10.5 3.75V5.5Z" fill="white"/>
                                            </svg>
                                            {translation?.ScheduleYourClass}
                                        </button>
                                         </Box> 
                                            </Box>
                                           
                                        </Box>
                                    </Box>

                            <Box className="TabsBox TabsMain">
                                <Box className="tabsBoxTop">
                                <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label={translation?.SessionNotes} {...a11yProps(0)} />
                                    <Tab label={translation?.ChatHis} {...a11yProps(1)} />
                                </Tabs>
                                </Box>
                            <Box>
                            <TabPanel value={tab} index={0}>
                                <Box className="outerCard">
                                    <iframe 
                                        src={session?.SessionNotesUrl}
                                        type="application/pdf"
                                        style={{ width: '100%', height: '350px' }} 
                                        title="PDF Viewer"
                                    />
                                </Box>
                            </TabPanel>

                            <TabPanel value={tab} index={1}>
                                <Box className="outerCard">

                                    <Box className="">
                                        <Box className="chat_head">
                                            <img src={session?.TutorProfilePicture} />
                                            <Box className="">
                                                <Typography sx={{ fontSize: '14px', color: '#000000', fontWeight: '500', lineHeight: '1', marginBottom: '3px', textTransform: 'capitalize' }}>{profileInfo?.UserName}</Typography>
                                                <Typography sx={{ fontSize: '12px', color: '#555555', fontWeight: '500', lineHeight: '1' }}>{translation[session?.OnlineStatusType + 'Status']}</Typography>
                                            </Box>
                                        </Box>
                                        <Box className="messages_box">
                                            { chatEvents?.map((chat, index) => {
                                                return (
                                                    <Box className={`main_chat ${chat?.UserUID === profileInfo?.UID ? 'right_chat' : 'left_chat'}`} key={index}>
                                                        { chat.DataType === '1' ?
                                                            <Typography className='para_chat'>{chat?.Message}</Typography> :
                                                            <Box className="para_chat_imgs" style={{ cursor: 'pointer' }}>
                                                                <img src={chat?.Message} onClick={() => openImage(chat?.Message)} width="40%" />
                                                            </Box>
                                                        }
                                                        <Typography className='time_chat'>{new Date(chat?.Time).toLocaleDateString('en-US')}</Typography>
                                                    </Box>
                                                )
                                            }) }
                                        </Box>
                                    </Box>
                                </Box>
                            </TabPanel>

                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Advertisement />
            </Box>
        </Box>
        <SessionReportModal isReport={isReport} reportId={reportId} translation={translation} onChangeReport={onChangeReport} closeReportModal={closeReportModal} onSubmitReport={onSubmitReport} />
        <ZoomImageContainer />
        <FooterSection/> 
    </div>
  )
}

export default SessionDetail