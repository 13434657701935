import React, { useState, useEffect } from "react";
import { Button, Box, Typography, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

import useTranslations from "../../custom-hooks/useTranslations";
import useSocket from "../../custom-hooks/useSocket";
import { API_BASE_URL } from "../../config/apiConfig";
import { axiosGet } from "../../services/api";

const QRCodeComponent = ({ qRvalue, connectId, setShowQR, sessionSave }) => {
  const navigate = useNavigate();
  const translations = useTranslations();
  const translation = {
    ...translations[0]?.Login,
    ...translations[26]?.QuickCall,
  }
  const { isConnected, socket } = useSocket();
  // const [qRvalue, setQRvalue] = useState("");
	// const [connectId, setConnectId] = useState();

  // const getQRcode = () => {
  //   axiosGet(`${API_BASE_URL}/GenerateQR`)
  //     .then((res) => {
  //       setQRvalue(res.data.Code);
  //       setConnectId(res.data.Data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // useEffect(() => {
  //   getQRcode();
  // }, []);

  const handleQRConnection = async (response) => {
    try {
      const data = JSON.parse(response);
      if (data) {
        localStorage.setItem('customToken', data.idToken);
        sessionStorage.setItem("userInfo", JSON.stringify({ uid: data.UserUID }));
        const res = await axiosGet(`${API_BASE_URL}/CustomToken`);
        const payload = {
          idToken: res?.data?.Token,
          expiresIn: 3600,
          localId: data.UserUID,
          expireTime: Date.now(),
        };
        sessionStorage.setItem("loginToken", JSON.stringify(payload));
        sessionSave(data.UserUID);
      }
    } catch (err) {
      console.log('Error: ', err);
    }
  };

  useEffect(() => {
    isConnected && socket?.on(connectId, handleQRConnection);

    return () => {
      socket?.off(connectId, handleQRConnection);
    };
  }, [isConnected, connectId]);

  return (
      <Box className="login_form" sx={{ mt: 1 }}>
        <Typography variant="h3" sx={{ fontSize: '24px', color: '#0F4D92', fontWeight: '600', marginBottom: '8px', textTransform: 'capitalize' }}>{translation?.Login}</Typography>
        <Typography variant="p" sx={{ fontSize: '18px', color: '#555555', fontWeight: '500', marginBottom: '32px' }}>{translation?.ScanQRUsingPhone}</Typography>
        <div className="mb-0" style={{ display: "flex", justifyContent: "center" }}>
          <Base64Image base64Image={qRvalue} />
        </div>
        <div className="mt-0">
          <Button
            variant="outlined"
            fullWidth
            sx={{ pt: 1.5, pb: 1.5 }}
            size="large"
            className="mt-20"
            onClick={() => {
              navigate("/login");
              setShowQR(false);
            }}
          >
            {translation?.BackBtn}
          </Button>
        </div>
      </Box>
  );
};

const Base64Image = ({ base64Image }) => {
  const dataUrl = `data:image/png;base64,${base64Image}`;
  return base64Image ? <img src={dataUrl} alt="QR Image" width={200} /> : <CircularProgress color="primary" />;
};

export default React.memo(QRCodeComponent);