import React, { useState, useEffect, useRef } from "react";
import { Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import useSocket from "../../custom-hooks/useSocket";

const QuickCallModal = ({ isOpen, isApiCalling, translation, handleClose }) => {
  const { socket, isConnected } = useSocket();
  const timerRef = useRef(null);
  const [timer, setTimer] = useState(60);
  const [onlineTutors, setOnlineTutors] = useState([]);

  const closed = () => {
    setTimer(60);
    handleClose();
  } 


  useEffect(() => {
    if (isOpen) {
      timerRef.current = setInterval(() => {
        setTimer((prevTimer) => {
          const newTimer = prevTimer - 1;

          if (newTimer <= 0) {
            clearInterval(timerRef.current);
            setTimer(55);
            handleClose();
          }

          return newTimer;
        });
      }, 1000);
    }

    return () => {
      clearInterval(timerRef.current);
    };
  }, [isOpen]);

  useEffect(() => {
    if (isConnected) {
      socket?.on('GetOnlineTutorResponseEvent', (data) => {
				const response = JSON.parse(data);
        if (Array.isArray(response.Data)) {
          setOnlineTutors(response.Data);
        }
 			})
    }

  }, [isConnected])

  useEffect(() => {
    return () => {
      socket?.off('GetOnlineTutorResponseEvent');
    }
  }, [])

  return (
    <Dialog
      open={isOpen}
      keepMounted
      // onClose={closed}
      aria-describedby="alert-dialog-slide-description"
      className="cis-dialog-1"
    >
      <DialogTitle>{translation?.ConnectWithFavTeacher}</DialogTitle>
      <DialogContent className="text-center">
        <div className="timing-box">
          <div className="timing-box_text-wrap">
            <span>
              {timer} <br /> {translation?.Sec}
            </span>
          </div>
          <div className="ripple_ timing-box-1"></div>
          <div className="ripple_ timing-box-2"></div>
          <div className="ripple_ timing-box-3"></div>
        </div>

        <Typography variant="h5">
          {translation?.RequestPushed} {onlineTutors?.length}{" "}
          {translation?.Tutors}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={closed}
          variant="contained"
          color="error"
          size="large"
          sx={{ px: 5 }}
          disabled={isApiCalling}
        >
          {isApiCalling ? translation?.Sending : translation?.QuickCancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuickCallModal;