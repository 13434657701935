import React, { useState, useEffect } from 'react'
import { Box, Typography, CircularProgress } from '@mui/material'
import Button from '@mui/material/Button';
import Header from "../header/Header";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import VideoThumbnail from 'react-video-thumbnail';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import { styled } from '@mui/material/styles';

import "../../App.css";
import "../subject/Subject.css";
import "../responsive/Responsive.css";
import "../session-list/SessionList.css";

import tutorpic2 from '../../assets/images/tutorpic2.png';

import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import useTranslations from '../../custom-hooks/useTranslations';
import useStudentDashboard from '../../custom-hooks/useStudentDashboard';
import useTutorInformation from '../../custom-hooks/useTutorInformation';
import { getTutorInformation, getTutorInformationSuccess } from '../../actions/getTutorInformation';
import { addToFavtTutor } from '../../actions/addToFavtTutorAction';
import { submitFeedBack } from '../../actions/submitFeedBackAction';

import momentDate from '../../services/momentDate';
import timeAgoDate from '../../services/timeAgoDate';

import FooterSection from "../footer/FooterSection";
import Advertisement from '../common-components/Advertisement';
import FeedbackModal from '../common-components/FeedbackModal';

import { API_BASE_URL } from '../../config/apiConfig';
import { axiosPost } from '../../services/api';

const TutorProfile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { tutorId } = useParams();
    const translations = useTranslations();
    const { markAsFavTutor } = useStudentDashboard();
    const { tutorInformation, addSessionRating } = useTutorInformation(atob(tutorId));
    console.log('tutorInformation: ', tutorInformation);
    const [feedbackModal, setFeedbackModal] = useState(sessionStorage.getItem('StudentSessionEnd') ? true : false);
    const [sessionFeedBack, setSessionFeedBack] = useState({
        TutorRating: '',
        FeedbackMessage: []
    });
    
    const [session, setSession] = useState({});
    const [isWithMe, setIsWithMe] = useState(true);
    const [sessionList, setSessionList] = useState([]);
    const profileInfo = JSON.parse(sessionStorage.getItem("ProfileInfo"));

    const translation = {
        ...translations[2]?.EditProfile,
        ...translations[7]?.PersonalInfo,
        ...translations[21]?.CommonFrontend,
        ...translations[26]?.QuickCall,
        ...translations[36]?.PracticeTest,
    }

    const handleBack = () => {
        navigate(-1);
    }

    const handleScheduleClass = () => {
        window.open('https://www.getyasa.com/book-private-class', '_blank')
    }

    const handleSessionRating = (session) => {
        setFeedbackModal(true)
        setSession(session);
    }

    const onChangeFeedback = (name, value) => {
        setSessionFeedBack((prev) => {
          if (name === 'FeedbackMessage') {
            const updatedFeedBack = prev?.FeedbackMessage?.includes(value)
              ? prev.FeedbackMessage.filter((item) => item !== value)
              : [...prev.FeedbackMessage, value];
      
            return {
              ...prev,
              FeedbackMessage: updatedFeedBack,
            };
          }
      
          return {
            ...prev,
            [name]: value,
          };
        });
    };

    const onSubmitFeedback = async () => {
        setFeedbackModal(false);
        setSessionList((prev) =>
            prev.map((item) =>
              item.ID === session.ID
                ? { ...item, Ratings: sessionFeedBack.TutorRating }
                : item
            )
        );
        addSessionRating(session.ID, sessionFeedBack.TutorRating);
        if (session.Type === 'QC') {
            const data = {
                SessionID: session?.ID,
                TutorRating: sessionFeedBack.TutorRating,
                SessionFeedbackMsg: sessionFeedBack.FeedbackMessage
            }
            dispatch(submitFeedBack(data))

        } else {
            const data = {
				SessionID: session?.ID,
				Ratings: sessionFeedBack.TutorRating,
				FeedbackID: sessionFeedBack.FeedbackMessage
			};
			await axiosPost(`${API_BASE_URL}/SavePrivateTutorFeedback`, data);
        }
        setSessionFeedBack({
            TutorRating: '',
            FeedbackMessage: []
        });
	}

    const handleChange = (e, page) => {
        dispatch(getTutorInformation({TutorID: atob(tutorId), WithMe: isWithMe, Page: page, PageSize: 10}))
    }

    const handleFavouriteTutor = () => {
        tutorInformation.TutorData[0].FavouriteTutor = tutorInformation.TutorData[0].FavouriteTutor === 'true' ? 'false' : 'true';
        dispatch(addToFavtTutor({ TutorID: atob(tutorId) }));
		markAsFavTutor(atob(tutorId));
	}

    const handleSession = (value) => {
        setIsWithMe(value);
        dispatch(getTutorInformation({TutorID: atob(tutorId), WithMe: value, Page: 1, PageSize: 10}))
        // const tutorSessions = tutorInformation?.TutorCall?.paginatedData?.filter((session) => session.WithMe === value);
        // setSessionList(tutorSessions);
    }

    const onSessionVideoClick = (session) => {
        navigate(`/session-detail/${btoa('TS' + session.ID)}/${btoa(session.TutorID)}`)
    }

    useEffect(() => {
        // const tutorSessions = tutorInformation?.TutorCall?.paginatedData?.filter((session) => session.WithMe === isWithMe)
        const tutorSessions = tutorInformation?.TutorCall?.paginatedData || []
        setSessionList(tutorSessions)

    }, [tutorInformation])


    useEffect(() => {
        return () => {
            dispatch(getTutorInformationSuccess({ Data: {} }))
        }
    }, [])

  return (
    <div>
    <Header/>
        <Box className="quikCallBanner">
            <Box className='OuterContainer' sx={{border:'none', borderRadius:'0px'}}>
                <Typography variant='h1'>
                    {translation?.Hi} {profileInfo?.UserName}, <Typography variant='div'> {translation?.WelcomeYasa}</Typography>
                </Typography>
            </Box>
       </Box>

       <Box className='OuterContainer containerShape' sx={{background:'#EAF4FF', padding:'30px'}}>
            <Box className='SectionIn'>
                <Box className="BackBtn">
                    <Button className='backBtn' variant="text" sx={{mb:2}} startIcon={localStorage.getItem("I18N_LANGUAGE") === "en" ? <ArrowBackIcon /> : <ArrowForward />} onClick={handleBack}> {translation?.BackBtn} </Button>
                </Box>
                <Box className="tutorMain">
                { !tutorInformation || Object.keys(tutorInformation).length === 0 ?
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box> : Object.keys(tutorInformation).length !== 0 && <>
                    <Box className="TutorAvailable" sx={{mb:3}}>
                        <Box className="tutorAvailableIn" sx={{gap:'30px', display:{xs: 'flex'}}}>
                        <Box 
                            className={`imageBox ${tutorInformation?.TutorData[0]?.OnlineStatusID === '1' ? 'greenCircle' : 
                            tutorInformation?.TutorData[0]?.OnlineStatusID === '2' ? 'redCircle' : ''}`}>
                            <img src={tutorInformation?.TutorData[0]?.ProfilePictureUrl} alt="tutor picture"></img>
                        </Box>
                        <Box className="sessionBox">
                            <Box className="contentBox" sx={{mb:1}}>
                                <Box className="contentInFavrouite">
                                    <Box className="content-in"  >
                                        <Typography variant='h3' sx={{ color: '#005DB8', fontSize: '18px' }}>
                                            {tutorInformation?.TutorData[0]?.UserName}
                                            <Typography variant='div' sx={{ color: 'rgba(0, 0, 0, 0.7);' }}>
                                                {` | ${tutorInformation?.TutorData[0]?.Nationality}`}
                                            </Typography>
                                            {/* <Typography variant='div' className={tutorInformation?.TutorData[0]?.OnlineStatus ? 'greenCircle' : 'redCircle'}>
                                                {tutorInformation?.TutorData[0]?.OnlineStatus ? ` ${translation?.Available}` : ` ${translation?.OfflineBtn}`}
                                            </Typography> */}
                                        </Typography>

                                        <Typography>
                                            {translation?.CompletedButton} {tutorInformation?.TutorData[0]?.TutorTotalSession} {translation?.Sessions} | {tutorInformation?.TutorData[0]?.TutorTotalTime} {translation?.PracticsMins}
                                        </Typography>
                                        <Typography>
                                            {translation?.TutorQualification}
                                            <Typography variant='div'>
                                                <b> {tutorInformation?.TutorData[0]?.Qualifications}</b>
                                            </Typography>
                                        </Typography>
                                        <Typography>
                                            {translation?.Experience}
                                            <Typography variant='div'>
                                                <b> {tutorInformation?.TutorData[0]?.Experience}</b>
                                            </Typography>
                                        </Typography>
                                        <Box className="RatingBox">
                                            <Stack spacing={2}>
                                                <Rating sx={{direction: 'ltr'}} name="half-rating-read" value={parseFloat(tutorInformation?.TutorData[0]?.ratings)} precision={0.5} readOnly />
                                            </Stack>
                                        </Box>
                                    </Box>
                                    <Button className={`favrouiteBtn ${tutorInformation?.TutorData[0]?.FavouriteTutor === "true" ? 'active' : ''}`} onClick={handleFavouriteTutor} sx={{ padding: '0', minWidth: '36px', maxWidth: '36px', background: 'none', marginLeft: 'auto' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <circle cx="12" cy="12" r="12" fill="#D8D8D8" />
                                            <path d="M12 4L13.7961 9.52786H19.6085L14.9062 12.9443L16.7023 18.4721L12 15.0557L7.29772 18.4721L9.09383 12.9443L4.39155 9.52786H10.2039L12 4Z" fill="white" />
                                        </svg>
                                    </Button>
                                </Box>
                                {/* <Box className="sessionBtn" onClick={handleFavouriteTutor}>
                                    <button className='radius4'> <svg width="14" height="14" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_4574_2478)">
                                    <path d="M11.5 6.03896V6.49896C11.4994 7.57717 11.1503 8.62629 10.5047 9.48987C9.85908 10.3534 8.95164 10.9852 7.91768 11.2909C6.88372 11.5966 5.77863 11.5599 4.76724 11.1862C3.75584 10.8126 2.89233 10.122 2.30548 9.21749C1.71863 8.31297 1.4399 7.24299 1.51084 6.16711C1.58178 5.09124 1.9986 4.06712 2.69914 3.24749C3.39968 2.42787 4.34639 1.85665 5.39809 1.61903C6.4498 1.38141 7.55013 1.49012 8.535 1.92896" stroke="#005DB8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M11.5 2.5L6.5 7.505L5 6.005" stroke="#005DB8" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_4574_2478">
                                    <rect width="12" height="12" fill="white" transform="translate(0.5 0.5)"/>
                                    </clipPath>
                                    </defs>
                                    </svg>
                                    {tutorInformation?.TutorData[0]?.FavouriteTutor ? translation.MarkAsUnfavourite : translation.MarkAsAFavourite}</button>
                                </Box> */}
                            </Box>
                            <Box className="availableBtn" sx={{gap:'15px', display:{xs: 'flex'}}}>
                                {/* <button className='darkYellow' >
                                    {translation?.ConnectNow}
                                </button> */}
                                <button className='darkBlue' onClick={handleScheduleClass}>
                                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.75 2.5H8.25V1.75C8.25 1.6837 8.22366 1.62011 8.17678 1.57322C8.12989 1.52634 8.0663 1.5 8 1.5C7.9337 1.5 7.87011 1.52634 7.82322 1.57322C7.77634 1.62011 7.75 1.6837 7.75 1.75V2.5H4.25V1.75C4.25 1.6837 4.22366 1.62011 4.17678 1.57322C4.12989 1.52634 4.0663 1.5 4 1.5C3.9337 1.5 3.87011 1.52634 3.82322 1.57322C3.77634 1.62011 3.75 1.6837 3.75 1.75V2.5H2.25C1.9186 2.5004 1.60089 2.63222 1.36655 2.86655C1.13222 3.10089 1.0004 3.4186 1 3.75V10.25C1.0004 10.5814 1.13222 10.8991 1.36655 11.1334C1.60089 11.3678 1.9186 11.4996 2.25 11.5H9.75C10.0814 11.4997 10.3992 11.368 10.6336 11.1336C10.868 10.8992 10.9997 10.5814 11 10.25V3.75C10.9997 3.41856 10.868 3.10077 10.6336 2.86641C10.3992 2.63205 10.0814 2.50026 9.75 2.5ZM10.5 10.25C10.5 10.4489 10.421 10.6397 10.2803 10.7803C10.1397 10.921 9.94891 11 9.75 11H2.25C2.05109 11 1.86032 10.921 1.71967 10.7803C1.57902 10.6397 1.5 10.4489 1.5 10.25V6H10.5V10.25ZM10.5 5.5H1.5V3.75C1.5 3.336 1.835 3 2.25 3H3.75V3.75C3.75 3.8163 3.77634 3.87989 3.82322 3.92678C3.87011 3.97366 3.9337 4 4 4C4.0663 4 4.12989 3.97366 4.17678 3.92678C4.22366 3.87989 4.25 3.8163 4.25 3.75V3H7.75V3.75C7.75 3.8163 7.77634 3.87989 7.82322 3.92678C7.87011 3.97366 7.9337 4 8 4C8.0663 4 8.12989 3.97366 8.17678 3.92678C8.22366 3.87989 8.25 3.8163 8.25 3.75V3H9.75C9.94891 3 10.1397 3.07902 10.2803 3.21967C10.421 3.36032 10.5 3.55109 10.5 3.75V5.5Z" fill="white"/>
                                    </svg>
                                    {translation?.ScheduleYourClass}
                                </button>
                            </Box>        
                        </Box>
                        </Box>
                    </Box>

                    <Box className="PersonalDetails">
                        <Typography variant='h4' className='blueline'>
                            <Typography variant='div' className='underLineBtn underLineBtn-Selected'>{translation?.PersonalDetails}</Typography> 
                        </Typography>
                        <Box className="contentBox">
                            <Typography variant='h5' sx={{mt:1, mb:1}}>{translation?.AboutTutor}</Typography>
                            <Typography>
                                {tutorInformation?.TutorData[0]?.TutorNotes}
                            </Typography>
                            <Box className="PersonalDetailsBox" sx={{mt:2, display:{xs: 'flex', justifyContent:'space-between'}}}>
                                {/* <Box sx={{width: '25%'}}>
                                    <Typography variant='h5' sx={{mb:0.5}}>{translation?.Experience}</Typography>
                                    <Typography>{tutorInformation?.TutorData[0]?.Experience} {translation?.Years}</Typography>
                                    <Typography>{translation?.IHave} {tutorInformation?.TutorData[0]?.Experience}+ {translation?.Years} {translation?.Experience} </Typography>
                                </Box> */}
                                <Box sx={{width: '33.33%'}}>
                                    <Typography variant='h5' sx={{mb:0.5}}>{translation?.ExamBoard}</Typography>
                                    <Box className="subjectBtns" sx={{ gap:0.5, display:{xs: 'flex', alignItems: 'center'}}}>
                                        {tutorInformation?.TutorData[0]?.EduBoard?.map((board, index) => {
                                            return <button key={index}>{board?.Value}</button>
                                        })}
                                    </Box>
                                </Box>
                                <Box sx={{width: '33.33%'}}>
                                    <Typography variant='h5' sx={{mb:0.5}}>{translation?.Grade}</Typography>
                                    <Box className="subjectBtns" sx={{ gap:0.5, display:{xs: 'flex', alignItems: 'center'}}}>
                                        {tutorInformation?.TutorData[0]?.Grades?.map((grade, index) => {
                                            return <button key={index}>{grade?.Value}</button>
                                        })}
                                    </Box>
                                </Box>
                                <Box sx={{width: '33.33%'}}>
                                    <Typography variant='h5' sx={{mb:0.5}}>{translation?.Skills}</Typography>
                                    <Box className="subjectBtns" sx={{ gap:0.5, display:{xs: 'flex', alignItems: 'center'}}}>
                                        {tutorInformation?.TutorData[0]?.Topics?.map((topic, index) => {
                                            return <button key={index}>{topic?.Value}</button>
                                        })}
                                    </Box>
                                </Box>
                                {/* <Box>
                                    <Typography variant='h5' sx={{mb:0.5}}>{translation?.TutorQualification}</Typography>
                                    <Typography>{tutorInformation?.TutorData[0]?.Qualifications}</Typography>
                                </Box> */}
                            </Box>
                        </Box>
                    
                    </Box>
                </> }

                    {/* {   sessionList?.length > 0 && */}
                        <Box className="tutorRecentSession" sx={{mt:3}}>
                            <Typography variant='h4' className='blueline' sx={{display:{xs:'flex'}, gap: 2}}>
                                <Typography variant='div' className={`underLineBtn ${isWithMe === true ? 'underLineBtn-Selected' : ''}`} onClick={() => handleSession(true)}>
                                    {`${translation?.SessionWith || ''} ${translation?.Mebtn || ''}`}
                                </Typography> 
                                <Typography variant='div' className={`underLineBtn ${isWithMe === false ? 'underLineBtn-Selected' : ''}`} onClick={() => handleSession(false)}>
                                    {`${translation?.SessionWith || ''} ${translation?.Otherbtn || ''}`}
                                </Typography> 
                            </Typography>

                        {/* <Box className="filterBox" sx={{mt:5, mb:5}}>
                            <Box className="filterhead" sx={{gap:2, display:{xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                                <Typography variant='h2' sx={{fontSize:'20px', color:'#0F4D92', fontWeight:'600', lineHeight:'28px', textTransform:'capitalize'}}>
                            
                                </Typography>
                                <Box className="fillterDropDown" sx={{gap:2, display:{xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                                
                                <FormControl fullWidth>
                                    <Select
                                        labelId="demo-simple-select-label" 
                                        id="demo-simple-select" 
                                        value={sessionType}
                                        onChange={handleSessionTypeChange}>
                                    <MenuItem value="0">{translation?.SelectYourSessionType}</MenuItem>
                                    {sessionTypeList.map((item, index) => {
                                        return <MenuItem value={item.Type} key={index}>{item.Value}</MenuItem>
                                    })}
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth>
                                    <Select
                                        labelId="demo-simple-select-label" 
                                        id="demo-simple-select-day" 
                                        value={sessionDay}
                                        onChange={handleSessionDayChange}>
                                    <MenuItem value="0">{translation?.SelectYourSessionDay}</MenuItem>
                                    {sessionDayList.map((item, index) => {
                                        return <MenuItem value={item?.Day} key={index}>{item?.Value}</MenuItem>
                                    })}
                                    </Select>
                                </FormControl>
                            
                                </Box>
                            </Box>
                        </Box> */}

                        { sessionList?.length === 0 && <Box className="outerCard" sx={{mt: 3, justifyContent: 'center'}} key={session.ID}>
                            <Box sx={{ fontWeight: '600' }}>
                                {isWithMe ? translation?.NoSessionWithYou : translation?.NoSessionWithOther}
                            </Box>
                        </Box> }

                        {   
                            sessionList?.map((session) => {
                                return <Box className="outerCard" sx={{mt: 3}} key={session.ID}>
                                    <Box className="VideoBox">
                                    {session?.RecordedSessionURL ? <>
                                        <VideoThumbnail
                                            videoUrl={session?.RecordedSessionURL}
                                            snapshotAtTime ={20} /> 
                                        <Box className="playBtn" onClick={() => onSessionVideoClick(session)}>
                                            <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.3861 5.24298C12.7788 6.00033 12.7788 7.99967 11.3861 8.75702L3.2393 13.1871C1.90661 13.9118 0.283854 12.9471 0.283854 11.4301L0.283854 2.56991C0.283854 1.05293 1.90661 0.0881979 3.23929 0.81289L11.3861 5.24298Z" fill="white"/>
                                            </svg>
                                        </Box>
                                    </> :
                                        <img src={tutorpic2} alt="tutor picture"></img>
                                    }
                                        <Box className="videoInBts" sx={{ display:{xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                                            <button className='hireBtn'>{session?.Type === 'PT' ? translation?.FindTutor : session?.Type === 'QC' ? translation?.QuickCallBtn : ''}</button>
                                            <button className='timerBtn'>{session?.CallDuration?.replace('.', ':') || 0}</button>
                                        </Box>
                                    </Box>
                                    <Box className="contentBox">
                                        <Typography variant='h5' sx={{mb:2, mb:0.5}}>
                                            {/* Obtain the greatest number of your digits , which is divisible by 15,25,40.. */}
                                            {session?.Notes}
                                        </Typography> 
                                        <Box className="TimeRankBox" sx={{ display:{xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                                            <Box className="InnerBox">
                                                <Box className="dateTimeBox" sx={{gap:1, display:{xs: 'flex', alignItems: 'center'}}}>
                                                    <Typography variant='div'>{timeAgoDate(session?.CreatedDate)}</Typography>
                                                    {/* <Typography variant='div'>{timeAgoDate(session?.CreatedDate)}</Typography>|
                                                    <Typography variant='div'>{momentDate(session?.CreatedDate)}</Typography> */}
                                                </Box>
                                                <Box className="mathsBtn">
                                                    { session?.TopicName?.map((item, index) => {
                                                        return <button className='sky radius4' key={index}>{item}</button>
                                                    }) }
                                                    {/* <button className='sky radius4'>{session?.TopicName}</button> */}
                                                </Box>
                                            </Box>
                                            
                                            <Box className="RatingBox">
                                                {   parseFloat(session?.Ratings) || session?.WithMe === false ?
                                                    <Stack spacing={2} sx={{mb:0.5, display:{xs: 'flex', alignItems: 'center'}}}>
                                                        <Rating sx={{direction: 'ltr'}} name="half-rating-read" value={parseFloat(session?.Ratings)} precision={0.5} readOnly />
                                                    </Stack> : <Box className="cardLeft">
                                                        <button onClick={() => handleSessionRating(session)}>{translation?.RateThisSession}</button>
                                                    </Box>
                                                }
                                            </Box>
                                        </Box>
                                        
                                                                                
                                    </Box>
                                </Box>
                            })
                        }
                          
                    </Box> 
                    {/* } */}
                </Box>

                {/* {   tutorInformation?.TutorCall?.paginatedData?.filter((session) => session.WithMe === isWithMe)?.length > 0 &&
                    <Box className="PaginationBox" sx={{ mt:4, mb:5, display:{xs: 'flex', alignItems: 'center', justifyContent:'flex-start'}}}>
                        <Stack spacing={2}>
                            <Pagination count={Math.ceil(tutorInformation?.TutorCall?.paginatedData?.filter((session) => session.WithMe === isWithMe)?.length / 10)} defaultPage={1} onChange={handleChange}  variant="outlined" color="primary" />
                        </Stack>
                    </Box>
                } */}

                { (tutorInformation && Object.keys(tutorInformation).length !== 0) &&
                    <Box className="PaginationBox" sx={{ mt:4, mb:5, display:{xs: 'flex', alignItems: 'center', justifyContent:'flex-start'}}}>
                        <Stack spacing={2}>
                            <Pagination count={tutorInformation?.TutorCall?.totalPages} defaultPage={1} onChange={handleChange}  variant="outlined" color="primary" />
                        </Stack>
                    </Box>
                }

                <Advertisement />
            </Box>
        </Box>
        <FeedbackModal isOpen={feedbackModal} feedback={sessionFeedBack} translation={translation} onChangeFeedback={onChangeFeedback} onSubmitFeedback={onSubmitFeedback} />
        <FooterSection/>
    </div>
  )
}

export default TutorProfile