import * as actionTypes from "./actionTypes";
import * as apiCall from "../services/api";
import { API_BASE_URL } from "../config/apiConfig";

const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length === 0
}

const getStudentProfileSuccess = (data) => {
  const noStudent = isObjectEmpty(data.Data)
  return {
    type: actionTypes.GET_STUDENT_PROFILE,
    payload: {
      student: noStudent ? [] : data.Data,
    },
  };
};

export const getStudentProfile = () => async (dispatch) => {
  return await apiCall
    .axiosGet(`${API_BASE_URL}/StudentProfileData`)
    .then((response) => {
      if (response) {
        sessionStorage.setItem('ProfileInfo', JSON.stringify(response?.data?.Data))
        dispatch(getStudentProfileSuccess(response?.data));
      }
    })
    .catch((error) => {
      console.log('Error: ', error);
    });
};